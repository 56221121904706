import React, { useEffect } from 'react';
import { account_service } from '../services/account_service';
import {useNavigate } from 'react-router-dom';

const AuthGuuardCompany = ({ children }) => {
    const navigate = useNavigate();

    let tokenCompany =  localStorage.getItem('tokenCompany')

    useEffect(() => {
        if (!account_service.isLoggedCompany() || !tokenCompany) {
            navigate('/auth/loginCompany');

        }
      }, [tokenCompany, navigate]);

    return children;
};

export default AuthGuuardCompany; 