import axios from "axios";
import { account_service } from "./account_service";

const Axios = axios.create({
    // baseURL: 'http://localhost:3300/api/fidelys/',
    baseURL: 'https://ecstatic-mcclintock.212-227-211-20.plesk.page/api/fidelys/',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // Initialize without 'Authorization' header here
    },
});

// INTERCEPTOR FOR TOKEN
Axios.interceptors.request.use(request => {
    if (account_service.isLogged()) {
        const token = localStorage.getItem('token');
        if (token) {
            request.headers.Authorization = token;
        }
    }
    return request;
}, error => {
    return Promise.reject(error);
});

Axios.interceptors.request.use(request => {
    if (account_service.isLoggedCompany()) {
        const tokenCompany = localStorage.getItem('tokenCompany');
        if (tokenCompany) {
            request.headers.Authorization = tokenCompany;
        }
    }
    return request;
}, error => {
    return Promise.reject(error);
});

// console.log("TokenCompany:", localStorage.getItem('tokenCompany')); // Log the token of company
// console.log("Token:", localStorage.getItem('token')); // Log the token of company

export default Axios;