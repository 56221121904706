import Axios from "./caller_service.js"

const addCompany = (company) => {
    return Axios.post("/utilisateur/createStructure/", company)
}
const addActivity = (activity) => {
    return Axios.post("/activite/create/", activity)
}
const getCompany = (id) => {
    return Axios.get("/utilisateur/detailStructure/" + id);
}
const getAllCompany = () => {
    return Axios.get("/utilisateur/listStructure/");
}
const getAllCommercial = () => {
    return Axios.get("/commercial");
}
const getAllTypeIntervention = () => {
    return Axios.get("/typeIntervention");
}
const getAllActivities = () => {
    return Axios.get("/activites");
}
const toggleCompanyStatus = (id) => {
    return Axios.put("/structure/statutChange/" + id);
}
const updateCompany = (id, companyUpdate) => {
    return Axios.put("/utilisateur/editStructure/" + id, companyUpdate);
}
export const company_services = {
    getAllCompany,
    getAllActivities,
    addCompany,
    getAllCommercial,
    updateCompany,
    toggleCompanyStatus,
    getCompany,
    getAllTypeIntervention,
    addActivity
}