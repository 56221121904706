import Axios from "./caller_service.js"

const getRole = () =>{
    return Axios.get("/roles")
}

const addUser = (user) =>{
    return Axios.post("/utilisateur/createUtilisateur" , user)
}

const getAllUsers = () =>{
    return Axios.get("/utilisateur/listCommercial/");
}
const getUser = (id) =>{
    return Axios.get("/utilisateur/detailCommercial/"+id);
}
const updateUser = (id , userUpdate) =>{
    return Axios.put("/utilisateur/editCommercial/" + id , userUpdate);
}
const toggleUserStatus = (id) =>{
    return Axios.put("/utilisateur/statutChange/"+id);
}

export const user_services = {
    getAllUsers,
    getUser,
    addUser,
    getRole,
    updateUser,
    toggleUserStatus
} 