/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import './auth.css';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { authentification } from '../../services/authentification';
import { account_service } from '../../services/account_service';

const LoginCompany = () => {

    const Navigate = useNavigate()
    const [verificationCode, setVerificationCode] = useState({});
    const [wait, setWait] = useState(true);

    // console.log("codeUnique : ", verificationCode);

    const onChange = (e) => {
        setVerificationCode({
            ...verificationCode,
            [e.target.name]: e.target.value
        })
    }

    const handleChange = (e) => {
        e.preventDefault();

        if (!verificationCode) {
            toast.error("Le code est obligatoire.")
            return;
        } else {
            setWait(false);
        }

        authentification.loginCompany(verificationCode)
            .then((res) => {
                // console.log(res.data);
                const tokenCompany = res.data.token
                const nom = res.data.nom
                if (res.status === 201) {
                    
                    Navigate('/company/dashboad');

                    // Récupérer la valeur depuis le localStorage lors de l'initialisation
                    account_service.saveTokenCompany(tokenCompany)
                    localStorage.setItem('nom',nom);
                    swal({
                        title: "Connection reussi.",
                        icon: "success",
                        button: "Fermer",
                    });
                }
            })
            .catch((err) => {
                setWait(true)

                console.log("err : ", err);

                if (err.response) {

                    if (err.response.status === (401)) {

                        swal({
                            title: "Code de connection incorrect.",
                            text: "Veuillez reessayer", 
                            icon: "error",
                            button: "Fermer",
                        });
                    } else if (err.response.status === 402) {
                        swal({
                            title: "Votre compte a été bloqué , veillez contacter l'administrateur ",
                            icon: "error",
                            button: "Fermer",
                        });
                    } else if (err.response.status === 408) {

                        toast.error("Délai dépassé par la requête")
                    } else if (err.response.status === 503) {

                        toast.error("Service non accessible de façon temporaire , en cours de maintenance")

                    } else if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application")
                    }
                }
            })

        // get all guest with code

    }

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="card p-5 shadow-lg">
                <h2 className="mb-4 text-center verif">Connexion</h2>
                <form className=' form justify-content-center' onSubmit={handleChange}>
                    <div className="">
                        <input type='password' className='form-control text-center'
                            name='codeUnique' maxLength={5} placeholder='EX : 10245'
                            value={verificationCode.codeUnique} onChange={onChange}
                        />
                        {
                            wait ?
                                <button type='submit' className="btn btn-success btn-block mt-2">
                                    Login
                                </button>
                                :
                                <button type='button' className="btn btn-success btn-block mt-2">
                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                    Connexion en cour..
                                </button>
                        }

                    </div>
                </form>
                {/* Passer la liste des invités à Dashboard */}
                {/* <Dashboard guest={guest} /> */}
            </div>
        </div>
    );
};

export default LoginCompany;