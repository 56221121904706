/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import './dashboad.css'
import { useNavigate } from 'react-router-dom';
import { user_services } from '../../services/user_services';
import toast from 'react-hot-toast';
import { customStyles } from '../../components/datatable/customStyles';
import DataTable from 'react-data-table-component';
import { dashboad } from '../../services/Dashboad';

const AdminDashboad = () => {
    const navigate = useNavigate();
    // navigation 
    const home = () => {
        navigate('/dashboad');
    };
    const list_user = () => {
        navigate('/list/user');
    };
    const [users, setUsers] = useState([])
    const [state, setState] = useState(0)

    const [totalUsers, setTotalUsers] = useState([])
    const [totalUserEnable, setTotalUserEnable] = useState([])
    const [totalUserDesable, setTotalUserDesable] = useState([])
    const [totalCompany, setTotalCompany] = useState([])
    const [totalCompanyEnable, setTotalCompanyEnable] = useState([])
    const [totalCompanyDesable, setTotalCompanyDesable] = useState([])
    const [totalPressing, setTotalPressing] = useState([])
    const [totalHairHouse, setTotalHairHouse] = useState([])

    // get all users function
    const getAllUsers = () => {
        user_services.getAllUsers()
            .then((res) => {
                console.log("users : ", res.data.allCommercial
                )
                setUsers(res.data.allCommercial)
            })
            .catch((err) => console.log(err))
    }
    console.log("userRes : ", users)

    // Retrieve the users when the component is mounted and each time the sorting/filtering criteria change
    useEffect(() => {
        getAllUsers();
    }, [state]);

    // Définir les colonnes du tableau
    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: '60px',
            sortable: true,
        },
        {
            name: 'Nom',
            selector: (row) => row.nom,
            sortable: true,
        },
        {
            name: 'Prénom',
            selector: (row) => row.prenom,
            sortable: true,
        },
        // {
        //     name: 'Email',
        //     selector: (row) => row.email,
        //     sortable: true,
        // },
        {
            name: 'Téléphone',
            selector: (row) => row.numeroTel,
            sortable: true,
        },
        {
            name: 'Role',
            selector: (row) => row.Role.role,
            sortable: true,
        },
        {
            name: 'Sexe',
            selector: (row) => row.sexe,
            sortable: true,
        },
        {
            name: 'Statu',
            selector: (row) => row.statut,
            sortable: true,
            width: '80px',
            cell: (row) => (
                <span className={`badge ${row.statut === true ? 'badge-success' : 'badge-danger'}`}>
                    {row.statut === true ? 'Activé' : 'Désactiver'}
                </span>
            ),
        },
        {
            name: 'Actions',    
            cell: (row) => (
                <div className='action-container d-flex'>
                    <div className={`custom-control custom-switch ${row.statut === true ? 'custom-switch-on-danger' : 'custom-switch-off-success'}`} title={row.statut === false ? "Activé l'utilisateur" : "Désactivé l'utilisateur"}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`customSwitch${row.id}`} // Utiliser un identifiant unique pour chaque bouton
                            checked={row.statut === true}
                            onClick={() => changeUserStatus(row.id)} // Passer l'identifiant de l à la fonction
                        />
                        <label className="custom-control-label link" htmlFor={`customSwitch${row.id}`}>
                        </label>
                    </div>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },

    ];

    // toggle user status
    const changeUserStatus = (id) => {
        user_services.toggleUserStatus(id)
            .then(res => {
                console.log('toggleStatust : ', res);
                if (res.data) {
                    setState((current) => current + 1)
                    toast.success("status modifier")
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 203) {
                        toast.error("Vous ne pouvez pas désactivé ou activé cet utilisateur")
                    }
                }
                console.error("err : ", err);
            });
    };

    // get total of users
    useEffect(() => {
        dashboad.totalUser()
            .then((res) => {
                console.log("totalUsersRes : ", res.data.nbrCommerciaux)
                setTotalUsers(res.data.nbrCommerciaux)
            })
            .catch((err) => console.log(err))
    }, []);

    // get total of company
    useEffect(() => {
        dashboad.totalCompany()
            .then((res) => {
                console.log("totalCompanyRes :: ", res.data.allStructure)
                setTotalCompany(res.data.allStructure)
            })
            .catch((err) => console.log(err))
    }, []);

    // get total commercial Enable
    useEffect(() => {
        dashboad.totalUserEnable()
            .then((res) => {
                console.log("totalUserEnableRes : ", res.data.nbrCommerciaux)
                setTotalUserEnable(res.data.nbrCommerciaux)
            })
            .catch((err) => console.log(err))
    }, [state]);

    // get total commercial desabled
    useEffect(() => {
        dashboad.totalUserDesable()
            .then((res) => {
                console.log("totalUserDesableRes : ", res.data.nbrCommerciaux)
                setTotalUserDesable(res.data.nbrCommerciaux)
            })
            .catch((err) => console.log(err))
    }, [state]);
    // get total HairHouse
    useEffect(() => {
        dashboad.totalHairHouse()
            .then((res) => {
                console.log("totalHairHouseRes : ", res.data.nbreSalon)
                setTotalHairHouse(res.data.nbreSalon)
            })
            .catch((err) => console.log(err))
    }, []);
    // get total Pressing
    useEffect(() => {
        dashboad.totalPressing()
            .then((res) => {
                console.log("totalPressingRes : ", res.data.nbrPressing)
                setTotalPressing(res.data.nbrPressing)
            })
            .catch((err) => console.log(err))
    }, []);
    // get total Company Enable
    useEffect(() => {
        dashboad.totalCompanyEnable()
            .then((res) => {
                console.log("totalCompanyEnableRes : ", res.data.nrStructure)
                setTotalCompanyEnable(res.data.nrStructure)
            })
            .catch((err) => console.log(err))
    }, []);
    // get total Company Desable
    useEffect(() => {
        dashboad.totalCompanyDesable()
            .then((res) => {
                console.log("totalCompanyDesableRes : ", res.data.nrStructure)
                setTotalCompanyDesable(res.data.nrStructure)
            })
            .catch((err) => console.log(err))
    }, []);


    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Tabeau de bord</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item active link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item active">Admin</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{totalUsers}</h3>
                                        <p>Total commerciaux</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{totalCompany}</h3>
                                        <p>Total structures</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-building" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-primary">
                                    <div className="inner">
                                        <h3>{totalUserEnable}</h3>
                                        <p>Commerciaux actif</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-unlock-alt" />

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-secondary">
                                    <div className="inner">
                                        <h3>{totalUserDesable}</h3>
                                        <p>Commerciaux bloqués</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-user-lock" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{totalHairHouse}</h3>
                                        <p>Sallon de coiffure</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-cut hair-salon-icon" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{totalPressing}</h3>
                                        <p>Pressing</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-tshirt" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-primary">
                                    <div className="inner">
                                        <h3>{totalCompanyEnable}</h3>
                                        <p>Structures activeés</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-unlock-alt" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-secondary">
                                    <div className="inner">
                                        <h3>{totalCompanyDesable}</h3>
                                        <p>Structures bloquées</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-lock blocked-account-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-list mr-2 text-white titleIcon"></i> Liste des commerciaux</h3>
                                        <span style={{ position: 'relative', float: 'right' }}>Total : <strong>{users.length}</strong></span>
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            columns={columns}
                                            data={users}
                                            striped
                                            highlightOnHover
                                            customStyles={customStyles}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AdminDashboad;