/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import '../public/dashboad.css';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../components/datatable/customStyles';
import DataTable from 'react-data-table-component';
import { clients_services } from '../../services/clients_services';
import QRCode from 'react-qr-code';
import { dashboad } from '../../services/Dashboad';
const CompanyDashboad = () => {
    const navigate = useNavigate();
    // navigation 
    const home = () => {
        navigate('/company/dashboad');
    };

    // declarations
    const [id, setId] = useState('')
    const [state, setState] = useState(0)
    const [filteredData, setFilteredData] = useState([]);
    const [totalClientLoyal, setTotalClientLoyal] = useState('');
    const [clientAleatoireRes, setClientAleatoireRes] = useState('');
    const [totalClient, setTotalClient] = useState('');
    const [tauxClientFidele, setTauxClientFidele] = useState('');
    const [sortGender, setSortGender] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [sortFilter, setSortFilter] = useState('');
    const [clientd, setClientd] = useState({})
    const [searchText, setSearchText] = useState('');
    const [loyalCustomer, setLoyalCustomer] = useState([]);

    // detail client info
    // console.log("id ------->: ", id);
    useEffect(() => {
        if (id !== '') {
            clients_services.getClient(id)
                .then((res) => {
                    console.log("ClientDetailRes:", res.data.client);
                    setClientd(res.data.client)
                })
                .catch((err) => {
                    console.error("err :", err);
                });
        }
    }, [id]);

    // get all client function
    const customerLoyal = () => {
        dashboad.customerLoyal()
            .then((res) => {
                console.log("customerLoyalRes : ", res.data.listClientFidele
                )
                setLoyalCustomer(res.data.listClientFidele
                )
            })
            .catch((err) => console.log("err ::", err))
    }
    console.log("customerLoyal : ", loyalCustomer)

    // Retrieve the clients when the component is mounted and each time the sorting/filtering criteria change
    useEffect(() => {
        customerLoyal();
    }, [sortOrder, sortFilter, sortGender, state]);

    // Définir les colonnes du tableau
    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: '60px',
            sortable: true,
        },
        // {
        //     name: 'Nom',
        //     selector: (row) => row.nom,
        //     sortable: true,
        // },
        {
            name: 'Prénom',
            selector: (row) => row.prenom,
            sortable: true,
        },
        // {
        //     name: 'Localisation',
        //     selector: (row) => row.localisation,
        //     sortable: true,
        // },
        {
            name: 'Téléphone',
            selector: (row) => row.numeroTel,
            sortable: true,
        },
        {
            name: 'Genre',
            selector: (row) => row.sexe,
            sortable: true,
        },
        {
            name: 'conso.',
            selector: (row) => row.intervention,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className='action-container d-flex'>
                    <button className="btn btn-primary btn-xs" title='Voir plus de detail' data-toggle="modal" data-target="#modal-detail" onClick={() => setId(row.id)} ><i className="fas fa-eye " /></button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // Function to filter and sort client based on current status
    const getClientsSortAdnfilter = () => {
        let clientsFilters = loyalCustomer

        // Apply gender filter
        if (sortGender) {
            clientsFilters = clientsFilters.filter((loyalCustomer) => loyalCustomer.sexe === sortGender);
        }

        // sort clients info
        clientsFilters.sort((a, b) => {
            if (sortOrder === 'desc') {
                if (sortFilter === 'nom') {
                    return b.nom.localeCompare(a.nom);
                } else if (sortFilter === 'prenom') {
                    return b.prenom.localeCompare(a.prenom);
                } else if (sortFilter === 'localisation') {
                    return b.localisation.localeCompare(a.localisation);
                }
            } else {
                if (sortFilter === 'nom') {
                    return a.nom.localeCompare(b.nom);
                } else if (sortFilter === 'prenom') {
                    return a.prenom.localeCompare(b.prenom);
                } else if (sortFilter === 'localisation') {
                    return a.localisation.localeCompare(b.localisation);
                }
            }
        });

        return clientsFilters
    }

    // get total of client loyal
    useEffect(() => {
        dashboad.nbrClientFidels()
            .then((res) => {
                console.log("totalClientLoyal :: ", res.data.nbrClientFidels)
                setTotalClientLoyal(res.data.nbrClientFidels)
            })
            .catch((err) => console.log(err))
    }, []);
    // get Client Aleatoire
    useEffect(() => {
        dashboad.nbrClientAleatoire()
            .then((res) => {
                console.log("clientAleatoireRes :: ", res.data.nbrClientAleatoire)
                setClientAleatoireRes(res.data.nbrClientAleatoire)
            })
            .catch((err) => console.log(err))
    }, []);
    // get total of client
    useEffect(() => {
        dashboad.totalClient()
            .then((res) => {
                console.log("totalClientRes :: ", res.data.nbrClient)
                setTotalClient(res.data.nbrClient)
            })
            .catch((err) => console.log(err))
    }, []);
    // get taux Client Fidele
    useEffect(() => {
        dashboad.tauxClientFidele()
            .then((res) => {
                console.log("tauxClientFideleRes :: ", res.data.pourcentageClient)
                setTauxClientFidele(res.data.pourcentageClient)
            })
            .catch((err) => console.log(err))
    }, []);
    // Mettre à jour les données filtrées et triées chaque fois que l'un des états change
    useEffect(() => {
        const sortedFilteredClients = getClientsSortAdnfilter();
        setFilteredData(sortedFilteredClients);
    }, [loyalCustomer, sortOrder, sortFilter, sortGender]);

    // Filtrer les données en fonction du texte de recherche
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        console.log("value -------> ", value);
        const filtered = getClientsSortAdnfilter().filter(item => {

            return (
                item.nom.toLowerCase().includes(value.toLowerCase()) ||
                item.prenom.toLowerCase().includes(value.toLowerCase()) ||
                item.localisation.toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };

    // put client into qr code 
    const clientInfo = JSON.stringify({
        Id: clientd.id,
        Nom: clientd.nom,
        Prenom: clientd.prenom,
        Telephone: clientd.numeroTel,
        Localisation: clientd.localisation,
        Intervention1: clientd.intervention1,
        Intervention2: clientd.intervention2,
        Sexe: clientd.sexe,
        DateCreation: clientd.createdAt,
        Structure: clientd.StructureId,
        Bonus: clientd.bonus
    });

    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Tabeau de bord</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item active link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item active">Structure</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                    <h3>{totalClient}</h3>
                                        <p>Total clients</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{totalClientLoyal}</h3>
                                        <p>Clients fideles</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-handshake" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{clientAleatoireRes}</h3>
                                        <p>Clients en attente</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-hourglass-start" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{tauxClientFidele}<sup>%</sup></h3>
                                        <p>Taux de fidelité </p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-heart" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-list mr-2 text-white titleIcon"></i>Liste des client fideles</h3>
                                        <span style={{ position: 'relative', float: 'right' }}>Total : <strong>{filteredData.length}</strong></span>
                                    </div>
                                    <div className="card-body">
                                        <form className="form filtre align-items-center d-flex flex-wrap justify-content-between">
                                            <div className="mb-3">
                                                <label htmlFor="orderOption" className="form-label">Trier par..</label>
                                                <select
                                                    className="form-select"
                                                    value={sortOrder}
                                                    onChange={(e) => setSortOrder(e.target.value)}
                                                    id='orderOption'
                                                >
                                                    <option value="">---</option>
                                                    <option value="asc">Ordre croissant</option>
                                                    <option value="desc">Ordre décroissant</option>
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="filterOption" className="form-label">Filtrer par..</label>
                                                <select
                                                    className="form-select"
                                                    value={sortFilter}
                                                    onChange={(e) => setSortFilter(e.target.value)}
                                                    id='filterOption'
                                                >
                                                    <option value="">---</option>
                                                    <option value="nom">Nom</option>
                                                    <option value="prenom">Prénom</option>
                                                    <option value="localisation">Localisation</option>
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="genderOption" className="form-label">Sexe..</label>
                                                <select
                                                    className="form-select"
                                                    value={sortGender}
                                                    onChange={(e) => setSortGender(e.target.value)}
                                                    id='genderOption'
                                                >
                                                    <option value="">---</option>
                                                    <option value="masculin">Masculin</option>
                                                    <option value="feminin">Féminin</option>
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <input
                                                    className='form-control search'
                                                    type="text"
                                                    placeholder="Rechercher..."
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </form>
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            striped
                                            highlightOnHover
                                            customStyles={customStyles}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* client detail modal */}
            <div className="modal fade" id="modal-detail">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-sm">Detail du client  : <strong className='ml-2 capitalize'>{clientd.nom + " " + clientd.prenom}</strong> </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button></div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-md-7 shadow text-center qr-container">
                                        <div className="qr-code-container">
                                            <QRCode
                                                className='link qr-code'
                                                value={clientInfo}
                                                size={256}
                                                style={{ width: '100%', height: '300px' }}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-5">
                                        {/* <div className="texte ">Bonjours tous le monda , c'est</div> */}
                                        <div className="fw-bold name mt-2"><h4><strong className='capitalize shadow px-2 py-2'>{clientd.nom + " " + clientd.prenom}</strong></h4></div>

                                        <div className="mt-3"><i className='fas fa-map-marker-alt mr-2 ml-2'></i>{clientd.localisation}</div>

                                        <div className="mt-3"><i className='fas fa-phone mr-2 ml-2'></i> (+237) {clientd.numeroTel} </div>

                                        {/* <div className="mt-3"><i className='fas fa-dumbbell mr-2 ml-2'></i> {clientd.intervention2} <strong>Kg</strong></div> */}

                                        <div className="mt-3"><i className='fas fa-infinity mr-2 ml-2'></i>{clientd.trace}  <strong>Visite(s)</strong></div>
                                        <div className="mt-3">
                                            {
                                                clientd.sexe === 'masculin' ? <i className="fas  mr-2 ml-2 fa-mars"></i> :
                                                    clientd.sexe === 'feminin' ? <i className="fas  mr-2 ml-2 fa-venus"></i> : ''
                                            }
                                            {clientd.sexe}
                                        </div>

                                        <div className="mt-3 "> Bonus atteint : <strong className='text-success'>{clientd.bonus}</strong> Fois</div>
                                        {/* <div className="mt-3 ml-2"><span className={clientd.statut ===  ? "badge badge-success" : "badge badge-danger"}>{clientd.bonus === true ? "Activé" : "Désactivé"}</span></div> */}

                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer  justify-content-between">
                            <button className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className="fas fa-times mr-1"></i>Fermer</button>
                            <div className="btn">
                                <button type="button" className="btn btn-primary btn-sm ml-2" title="Ajouter un nouveau client" data-toggle="modal" data-target="#modal-add" data-dismiss="modal">
                                    <i className="fas fa-plus mr-1" ></i>
                                    Ajouter
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyDashboad;