import React, { useEffect, useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';
// import { guest_service } from '../../services/guest_service';
import swal from 'sweetalert';
import "./scanneurCodeQr.css"
import { useNavigate } from 'react-router-dom';
import { clients_services } from '../../services/clients_services';
const ScanneurCodeQr = () => {
    // Navigations
    const navigate = useNavigate()
    const home = () => {
        navigate('/company/dashboad');
    };
    const list_client = () => {
        navigate('/company/list/client');
    };

    const qrFileRef = useRef(null); // Réf pour le lecteur QR de fichier
    const [scanResult, setScanResult] = useState({}); // État pour le résultat du scan de fichier
    const [id, setId] = useState(''); // 
    const [wait, setWait] = useState(true)
    const [lastId, setLastId] = useState('');
    const [client, setClient] = useState('');
    const [message, setmessage] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [typeIntervention, setTypeIntervention] = useState('');


    useEffect(() => {
        if (id !== '') {
            clients_services.scanClient1(id)
                .then((res) => {
                    console.log("scanCodeQr::: ", res);
                    console.log("typeIntervention-->", res.data.typeIntervention);
                    setTypeIntervention(res.data.typeIntervention)
                    if (res.data.typeIntervention === 2) {
                        // Ouvrir la modal Bootstrap
                        const modal = new window.bootstrap.Modal(document.getElementById('modal-scan'));
                        modal.show();
                        return
                    } else {
                        setmessage(res.data.message)
                        swal({
                            text: message,
                            icon: "success",
                            button: "Fermer",
                        });
                    }
                    if (id !== '') {
                        console.log("id------>> ", id);
                        clients_services.getClient(id)
                            .then((res) => {
                                console.log("ClientDetailRes:", res.data.client);
                                setClient(res.data.client)
                            })
                            .catch((err) => {
                                console.error("err :", err);
                            });
                    }

                })
                .catch((err) => {
                    console.error("err:", err);
                    if (err.response) {
                        if (err.response.status === 403) {
                            swal({
                                text: "Le client n'appartient pas a la structure",
                                icon: "error",
                                button: "Fermer",
                            });
                        } else if (err.response.status === 404) {
                            swal({
                                text: "Client introuvable.",
                                icon: "error",
                                button: "Fermer",
                            });
                        } else if (err.response.status === 400) {
                            navigate("/auth/loginCompany");
                            swal({
                                text: "Votre séssion a expiré, veillez vous connecter",
                                icon: "error",
                                button: "Fermer",
                            });
                        } else if (err.response.status === 401) {
                            swal({
                                text: "Votre compte n'existe pas ",
                                icon: "error",
                                button: "Fermer",
                            });
                        }
                    }
                });
        }
    }, [id]);

    const onChange = (e) => {
        setQuantity({
            ...quantity,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(" Quantity=", quantity.qte);
        return clients_services.scanClient2(id, quantity)
            .then((res) => {
                console.log("scanClient2Res::--->", res);
                if (res.status === 200) {
                    if (id !== '') {
                        console.log("id------>> ", id);
                        clients_services.getClient(id)
                            .then((res) => {
                                console.log("ClientDetailRes:", res.data.client);
                                setClient(res.data.client)
                            })
                            .catch((err) => {
                                console.error("err :", err);
                                if (err.response) {
                                    if (err.response.status === 400) {
                                        navigate("/auth/loginCompany");
                                        swal({
                                            text: "Votre séssion a expiré, veillez vous connecter",
                                            icon: "error",
                                            button: "Fermer",
                                        });
                                    }
                                }
                            });
                    }
                }
                swal({
                    text: res.data.message,
                    icon: "success",
                    button: "Fermer",
                });
                // Fermer la modal
                document.getElementById('modal-scan').classList.remove('show');
                document.getElementById('modal-scan').setAttribute('aria-hidden', 'true');
                document.body.classList.remove('modal-open');
                document.querySelector('.modal-backdrop').remove();
                setQuantity({
                    qte: ""
                });
            })
            .catch((err) => {
                console.error("err:", err);
                if (err.response) {
                    if (err.response.status === 403) {
                        swal({
                            text: "Le client n'appartient pas a la structure",
                            icon: "error",
                            button: "Fermer",
                        });
                    } else if (err.response.status === 404) {
                        swal({
                            text: "Client introuvable.",
                            icon: "error",
                            button: "Fermer",
                        });
                    } else if (err.response.status === 400) {
                        navigate("/auth/loginCompany");
                        swal({
                            text: "Votre séssion a expiré, veillez vous connecter",
                            icon: "error",
                            button: "Fermer",
                        });
                    } else if (err.response.status === 401) {
                        swal({
                            text: "Votre compte n'existe pas",
                            icon: "error",
                            button: "Fermer",
                        });
                    }
                }
            });
    };

    const handleScanFile = (result) => {
        if (result) {
            try {
                setScanResult(result);
                const parsedData = JSON.parse(result);
                const { Id } = parsedData;
                setId(Id);
                setLastId(Id);

                // Ignorez si l'ID est le même que le dernier traité
                if (Id === lastId) {
                    swal({
                        text: "Client déjà scanner, essayer un autre.",
                        icon: "error",
                        button: "Fermer",
                    });
                    return
                }
                console.log("resultRes :", result);
            } catch (error) {
                console.error("Une erreur c'est produite lors du scan:", error);
                swal({
                    text: "QR Code Invalide.",
                    icon: "error",
                    button: "Fermer",
                });
            }
        }
    };

    // console.log("id ------->", id);


    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Gestion des clients</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item link"><a onClick={home}>Acceuil</a></li>
                                <li className="breadcrumb-item link"><a onClick={list_client}>Clients</a></li>
                                <li className="breadcrumb-item active">Scan Code Qr</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-3">
                                <div className="card-header">
                                    <h5 className='card-title'><i className="fas fa-qrcode mr-2"></i>Scanner le code Qr pour avoir plus d'informations</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row ">

                                        <div className="col-md-7 shadow ">
                                            <QrReader className='mt-0'
                                                ref={qrFileRef}
                                                delay={300}
                                                onResult={id === '' ? handleScanFile : undefined}
                                                legacyMode={false}
                                            />
                                        </div>
                                        {
                                            client ?
                                                <div className="col-md-5 infoDetail">
                                                    <div className="texte ">Bonjours tous le monde , c'est</div>
                                                    <div className="fw-bold name mt-2"><h4><strong className='capitalize shadow px-2 py-2'>{client.nom + " " + client.prenom}</strong></h4></div>

                                                    <div className="mt-3"><i className='fas fa-map-marker-alt mr-2 ml-2'></i>{client.localisation}</div>

                                                    <div className="mt-3"><i className='fas fa-phone mr-2 ml-2'></i> (+237) {client.numeroTel} </div>

                                                    <div className="mt-3"><i className={typeIntervention ? "fas fa-dumbbell mr-2 ml-2" : "fas fa-infinity mr-2 ml-2"}></i> {client.trace} <strong>{typeIntervention ? "Kg" : "Visite(s)"}</strong></div>


                                                    <div className="mt-3">
                                                        {
                                                            client.sexe === 'masculin' ? <i className="fas  mr-2 ml-2 fa-mars"></i> :
                                                                client.sexe === 'feminin' ? <i className="fas  mr-2 ml-2 fa-venus"></i> : ''
                                                        }
                                                        {client.sexe}
                                                    </div>

                                                    <div className="mt-3 "> Bonus atteint : <strong className='text-success'>{client.bonus}</strong></div>
                                                </div>
                                                : <p className='ml-3'>En attente du scan..</p>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* modals */}
            {/* update client quntity modal */}
            <div className="modal fade" id="modal-scan">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Veuillez rensigner la quantite</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form className="row form" onSubmit={onSubmit}>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row ">
                                        <input type="number" name='qte' value={quantity.qte}
                                            onChange={onChange}
                                            placeholder='Veillez entrer la quantité' className="form-control mt-3" />
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className='fas fa-times f-3 mr-1'></i>Fermer</button>
                                        <div className="d-flex">
                                            {
                                                wait ?
                                                    <button type="submit" className="btn btn-warning btn-sm" title='Enregistrer'> <i className='fas fa-plus mr-1 f-3'></i>Enregistrer</button>
                                                    :
                                                    <button className="btn btn-succes btn-sm"> <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Enregistrement..</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScanneurCodeQr;