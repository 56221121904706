import Axios from "./caller_service.js"

const addClient = (client) => {
    return Axios.post("/structure/client/create/", client)
}
const getAllClients = () => {
    return Axios.get("/structure/listClient/");
}
const getClient = (id) => {
    return Axios.get("/structure/detailClient/" + id);
}
const scanClient1 = (id) => {
    return Axios.put("/client/validation/" + id);
}
const scanClient2 = (id, quantite) => {
    return Axios.put("/client/validationType2/" + id, quantite);
}
const updateClient = (id, clientUpdate) => {
    return Axios.put("structure/editClient/" + id, clientUpdate);
}

export const clients_services = {
    getAllClients,
    getClient,
    addClient,
    updateClient,
    scanClient1,
    scanClient2
}