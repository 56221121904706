/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logom from "../../assets/user/user/4.jpg";
import logow from "../../assets/company/téléchargement3.png";
import { customStyles } from '../../components/datatable/customStyles';
import DataTable from 'react-data-table-component';
import { user_services } from '../../services/user_services';
import { dashboad } from '../../services/Dashboad';
import { company_services } from '../../services/company_services';
const CommercialDashboad = () => {
    const navigate = useNavigate();
    // navigation 
    const home = () => {
        navigate('/dashboad');
    };

    // declarations
    const [id, setId] = useState('')
    const [companies, setCompanies] = useState([])
    const [state, setState] = useState(0)
    const [user, setUser] = useState({})
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [company, setCompany] = useState({})
    const [activitie, setActivitie] = useState([])
    const [sortOrder, setSortOrder] = useState('');
    const [sortFilter, setSortFilter] = useState('');
    const [sortStatus, setSortStatus] = useState('');
    const [sortType, setSortType] = useState('');

    console.log("sortStatusRes ::", sortStatus);
    console.log("sortTypeRes ::", sortType);


    // get all activites sertor function
    useEffect(() => {
        company_services.getAllActivities()
            .then((res) => {
                // console.log("activitesRes : ", res.data.activites)
                setActivitie(res.data.activites)
            })
            .catch((err) => console.log(err))
    }, [])

    // detail company info
    useEffect(() => {
        if (id !== '') {
            company_services.getCompany(id)
                .then((res) => {
                    console.log("companyRes :", res.data);
                    setCompany(res.data)
                })
                .catch((err) => {
                    console.error("err :", err);
                });
        }
    }, [id]);

    // get all companies function
    const getAllCompanies = () => {
        dashboad.listCompaniesEnable()
            .then((res) => {
                console.log("companyRes : ", res.data.structure
                )
                setCompanies(res.data.structure)
            })
            .catch((err) => console.log(err))
    }
    console.log("companies : ", companies)

    // Retrieve the users when the component is mounted and each time the sorting/filtering criteria change
    useEffect(() => {
        getAllCompanies();
    }, [state]);

    // Retrieve the users when the component is mounted and each time the sorting/filtering criteria change
    useEffect(() => {
        getAllCompanies();
    }, [sortOrder, sortFilter, sortStatus, sortType, state]);


    // Function to filter and sort companies based on current status
    const getCompaniesSortAdnfilter = () => {
        let companiesFilters = companies

        // Apply status filter
        if (sortStatus) {
            companiesFilters = companiesFilters.filter((companies) => companies.statut === sortStatus);
        }

        // Apply activitie filter
        if (sortType) {
            companiesFilters = companiesFilters.filter((companies) => companies.activiteId === sortType);
        }

        // sort companies
        companiesFilters.sort((a, b) => {
            if (sortOrder === 'desc') {
                if (sortFilter === 'nom') {
                    return b.nom.localeCompare(a.nom);
                } else if (sortFilter === 'nomBoss') {
                    return b.nomBoss.localeCompare(a.nomBoss);
                } else if (sortFilter === 'localisation') {
                    return b.localisation.localeCompare(a.localisation);
                }
            } else {
                if (sortFilter === 'nom') {
                    return a.nom.localeCompare(b.nom);
                } else if (sortFilter === 'nomBoss') {
                    return a.nomBoss.localeCompare(b.nomBoss);
                } else if (sortFilter === 'localisation') {
                    return a.localisation.localeCompare(b.localisation);
                }
            }
        });

        return companiesFilters
    }

    // Mettre à jour les données filtrées et triées chaque fois que l'un des états change
    useEffect(() => {
        const sortedFilteredGuests = getCompaniesSortAdnfilter();
        setFilteredData(sortedFilteredGuests);
    }, [companies, sortOrder, sortFilter, sortStatus, sortType]);

    // Filtrer les données en fonction du texte de recherche
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        const filtered = getCompaniesSortAdnfilter().filter(item => {

            return (
                item.nom.toLowerCase().includes(value.toLowerCase()) ||
                item.nomBoss.toLowerCase().includes(value.toLowerCase()) ||
                item.localisation.toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };

    // Définir les colonnes du tableau
    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: '60px',
            sortable: true,
        },
        {
            name: 'Nom',
            selector: (row) => row.nom,
            sortable: true,
        },
        {
            name: 'Nom Boss',
            selector: (row) => row.nomBoss,
            sortable: true,
        },
        {
            name: 'Téléphone',
            selector: (row) => row.numeroTel,
            sortable: true,
        },
        {
            name: 'Localisation',
            selector: (row) => row.localisation,
            sortable: true,
        },
        {
            name: 'Limite 1',
            selector: (row) => row.limite1,
            sortable: true,
        },
        {
            name: 'Limite 2',
            selector: (row) => row.limite2,
            sortable: true,
        },
        {
            name: 'Statu',
            selector: (row) => row.statut,
            sortable: true,
            width: '80px',
            cell: (row) => (
                <span className={`badge ${row.statut === true ? 'badge-success' : 'badge-danger'}`}>
                    {row.statut === true ? 'Activé' : 'Désactiver'}
                </span>
            ),
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className='action-container d-flex'>
                    <button className="btn btn-primary btn-xs" title='Voir plus' data-toggle="modal" data-target="#modal-detail" onClick={() => setId(row.id)} ><i className="fas fa-eye " /></button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark titleMain">Tabeau de bord</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item active link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item active">Commercial</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-list mr-2 text-white titleIcon"></i>Liste des structures activées</h3>
                                        <span style={{ position: 'relative', float: 'right' }}>Total : <strong>{companies.length}</strong></span>
                                    </div>
                                    <div className="card-body">
                                        <form className="filtre align-items-center d-flex flex-wrap justify-content-between">
                                            <div>
                                                <label htmlFor="orderOption"> Trier par..</label>
                                                <select className="" value={sortOrder} name={sortOrder} onChange={(e) => setSortOrder(e.target.value)} id='orderOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="asc">Ordre croissant</option>
                                                    <option value="desc">Ordre decroissant</option>
                                                </select>
                                            </div>

                                            <div>
                                                <label htmlFor="filterOption"> Filtrer par..</label>
                                                <select className=" ml-2" value={sortFilter} name={sortFilter} onChange={(e) => setSortFilter(e.target.value)} id='filterOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="nom">Nom</option>
                                                    <option value="nomBoss">Nom Boss</option>
                                                    <option value="localisation">Localisation</option>
                                                </select>
                                            </div>

                                            {/* <div>
                                                <label htmlFor="statusOption"> Status..</label>
                                                <select className=" ml-2" value={sortStatus} name={sortStatus} onChange={(e) => setSortStatus(e.target.value)} id='statusOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="true">Actif</option>
                                                    <option value="false">Inactif</option>
                                                </select>
                                            </div> */}

                                            <div>
                                                <label htmlFor="typeOption">Secteur..</label>
                                                <select className="  ml-2" name={sortType} onChange={(e) => setSortType(e.target.value)} id='typeOption'>
                                                    <option selected>---</option>
                                                    {
                                                        activitie.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.domaine}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="">
                                                <input
                                                    className='search'
                                                    type="text"
                                                    placeholder="Rechercher..."
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </form>
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            striped
                                            highlightOnHover
                                            customStyles={customStyles}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* company detail modal */}
            <div className="modal fade" id="modal-detail">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-sm">Detail de la strucure  : <strong className='ml-2 capitalize'>{company.nom}</strong> </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button></div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-md-7 shadow align-items-center justify-content-center">
                                        <div className="logo text-center  px-2 py-2 d-flex">
                                            <img src={logow} alt="logo Structure" width="300px" height="200px" />
                                            <span className='mt-3 align-items-center text-center capitalize'>{company.nom}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-5">
                                        {/* <div className="texte ">Bonjours tous le monda , c'est</div> */}
                                        <div className="fw-bold name mt-2"><h4><strong className='capitalize shadow px-2 py-2'>{company.nom}</strong></h4></div>

                                        <div className="mt-3"><i className='fas fa-envelope mr-2 ml-2'></i>{company.nomBoss}</div>

                                        <div className="mt-3"> <i className='fas fa-map-marker-alt mr-2 ml-2'></i>{company.localisation}</div>

                                        <div className="mt-3"><i className='fas fa-wrench fa-spin mr-2 ml-2'></i>
                                            <span >
                                                {company.typeInterventionId === 1 ? 'Nb.visite' : 'Quantité'}
                                            </span>
                                        </div>

                                        <div className="mt-3"><i className='fas fa-phone mr-2 ml-2'></i> (+237) {company.numeroTel} </div>

                                        <div className="mt-3">
                                            <i className='fas fa-map-marker-alt mr-2 ml-2'></i>
                                            {company.numeroTel}
                                        </div>


                                        <div className="mt-3 ml-2"><span className={company.statut === true ? "badge badge-success" : "badge badge-danger"}>{company.statut === true ? "Activé" : "Désactivé"}</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer  justify-content-between">
                            <button className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className="fas fa-times mr-1"></i>Fermer</button>

                            <div className="btn">
                                <button className="btn btn-danger btn-sm" title='Désactivé'>
                                    Désactivé
                                </button>
                                <button type="button" className="btn btn-warning btn-sm ml-2" title='Modifier' data-dismiss="modal" data-toggle="modal">
                                    <i className="fas fa-edit mr-1" title="Modifier les info l'utilisateur"></i>
                                    Modifier
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommercialDashboad;