/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../components/datatable/customStyles';
import logom from "../../assets/user/user/4.jpg";
import logow from "../../assets/user/user/1.jpeg";
import { user_services } from '../../services/user_services';
import toast from 'react-hot-toast';

const ListUser = () => {
    // Navigations
    const navigate = useNavigate()
    const add_user = () => {
        navigate('/add/user');
    }
    const home = () => {
        navigate('/');
    };
    const list_user = () => {
        navigate('/list/user');
    };

    // declaration 
    const [wait, setWait] = useState(true)
    const [state, setState] = useState(0)
    const [roles, setRoles] = useState([])
    const [id, setId] = useState('')
    const [user, setUser] = useState({})
    const [users, setUsers] = useState([])
    const [updateId, setUpdateId] = useState('')
    const [userUpdate, setUserUpdate] = useState({})
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('');
    const [sortFilter, setSortFilter] = useState('');
    const [sortStatus, setSortStatus] = useState('');
    const [sortGender, setSortGender] = useState('');

    console.log("sortOrderRes : " + sortOrder);
    console.log("sortFilterres : " + sortFilter);
    console.log("sortStatusres : " + sortStatus);
    console.log("sortGenderres : " + sortGender);

    // detail user info
    // console.log("idRes :", id);
    useEffect(() => {
        if (id !== '') {
            user_services.getUser(id)
                .then((res) => {
                    console.log("userRes :::", res.data);
                    setUser(res.data)
                })
                .catch((err) => {
                    console.error("err :", err);
                });
        }
    }, [id, state]);

    // get all users function
    const getAllUsers = () => {
        user_services.getAllUsers()
            .then((res) => {
                console.log("users : ", res.data.allCommercial
                )
                setUsers(res.data.allCommercial)
            })
            .catch((err) => console.log(err))
    }
    console.log("userRes : ", users)

    // Retrieve the users when the component is mounted and each time the sorting/filtering criteria change
    useEffect(() => {
        getAllUsers();
    }, [sortOrder, sortFilter, sortGender, state]);

    // Function to filter and sort users based on current status
    const getUsersSortAdnfilter = () => {
        let usersFilters = users
        if (sortStatus !== "") { // Check for empty value (both true and false)
            usersFilters = usersFilters.filter((user) => user.statut === (sortStatus === "1"));
        }

        // Apply gender filter
        if (sortGender) {
            usersFilters = usersFilters.filter((users) => users.sexe === sortGender);
        }

        // sort users
        usersFilters.sort((a, b) => {
            if (sortOrder === 'desc') {
                if (sortFilter === 'nom') {
                    return b.nom.localeCompare(a.nom);
                } else if (sortFilter === 'prenom') {
                    return b.prenom.localeCompare(a.prenom);
                } else if (sortFilter === 'email') {
                    return b.email.localeCompare(a.email);
                }
            } else {
                if (sortFilter === 'nom') {
                    return a.nom.localeCompare(b.nom);
                } else if (sortFilter === 'prenom') {
                    return a.prenom.localeCompare(b.prenom);
                } else if (sortFilter === 'email') {
                    return a.email.localeCompare(b.email);
                }
            }
        });

        return usersFilters
    }

    // Mettre à jour les données filtrées et triées chaque fois que l'un des états change
    useEffect(() => {
        const sortedFilteredGuests = getUsersSortAdnfilter();
        setFilteredData(sortedFilteredGuests);
    }, [users, sortOrder, sortFilter, sortGender]);

    // Filtrer les données en fonction du texte de recherche
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        console.log("value --->", value);
        const filtered = getUsersSortAdnfilter().filter(item => {
            if (!value) return true;

            return (
                (item.nom && item.nom.toLowerCase().includes(value.toLowerCase())) ||
                (item.prenom && item.prenom.toLowerCase().includes(value.toLowerCase())) ||
                (item.email && item.email.toLowerCase().includes(value.toLowerCase())) ||
                (item.sexe && item.sexe.toLowerCase().includes(value.toLowerCase()))
            );
        });
        setFilteredData(filtered);
    };

    // Définir les colonnes du tableau
    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: '60px',
            sortable: true,
        },
        {
            name: 'Nom',
            selector: (row) => row.nom,
            sortable: true,
        },
        {
            name: 'Prénom',
            selector: (row) => row.prenom,
            sortable: true,
        },
        // {
        //     name: 'Email',
        //     selector: (row) => row.email,
        //     sortable: true,
        // },
        {
            name: 'Téléphone',
            selector: (row) => row.numeroTel,
            sortable: true,
        },
        {
            name: 'Role',
            selector: (row) => row.Role.role,
            sortable: true,
        },
        {
            name: 'Sexe',
            selector: (row) => row.sexe,
            sortable: true,
        },
        {
            name: 'Statu',
            selector: (row) => row.statut,
            sortable: true,
            width: '80px',
            cell: (row) => (
                <span className={`badge ${row.statut === true ? 'badge-success' : 'badge-danger'}`}>
                    {row.statut === true ? 'Activé' : 'Désactiver'}
                </span>
            ),
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className='action-container d-flex'>
                    <button className="btn btn-primary btn-xs" title='Voir plus' data-toggle="modal" data-target="#modal-detail" onClick={() => setId(row.id)} ><i className="fas fa-eye " /></button>
                    <button className="btn btn-warning btn-xs mx-1" title='Modifier' data-toggle="modal" data-target="#modal-edit" onClick={() => setUpdateId(row.id)}><i className="fas fa-edit" /></button>
                    <div className={`custom-control custom-switch ${row.statut === true ? 'custom-switch-on-danger' : 'custom-switch-off-success'}`} title={row.statut === false ? "Activé l'utilisateur" : "Désactivé l'utilisateur"}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`customSwitch${row.id}`} // Utiliser un identifiant unique pour chaque bouton
                            checked={row.statut === true}
                            onClick={() => changeUserStatus(row.id)} // Passer l'identifiant de l à la fonction
                        />
                        <label className="custom-control-label link" htmlFor={`customSwitch${row.id}`}>
                        </label>
                    </div>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },

    ];

    // get all roles
    useEffect(() => {
        user_services.getRole()
            .then((res) => {
                console.log("role : ", res);
                setRoles(res.data.roles)
            })
            .catch((err) => console.log(err))
    }, []);

    // update user 
    useEffect(() => {
        if (updateId !== '') {
            user_services.getUser(updateId)
                .then((res) => {
                    console.log("userUpdateRES", res.data)
                    setUserUpdate(res.data)
                })
                .catch((err) => {
                    console.error("err :", err);
                });
        }
    }, [updateId]);

    // console.log("userUpdate", userUpdate)
    const onChange = (e) => {
        setUserUpdate({
            ...userUpdate,
            [e.target.name]: e.target.value
        })
    }
    console.log("userUpdateId", updateId);

    const onSubmit = (e) => {
        e.preventDefault()
        if (
            !userUpdate.numeroTel ||
            !userUpdate.nom ||
            !userUpdate.roleId ||
            !userUpdate.sexe
        ) {
            toast.error("Veuillez remplir tous les champs obligatoires!");
            return;
        }

        if (userUpdate.numeroTel && userUpdate.numeroTel.length < 9) {
            toast.error("Le numero de telephone doit contenir au moins 9 chiffres.");
            return;
        }

        if (userUpdate.nom && userUpdate.nom.length > 30) {
            toast.error("Le champ nom ne doit pas etre au dela de 30 caracteres.");
            return;
        }

        if (userUpdate.prenom && userUpdate.prenom.length > 30) {
            toast.error("Le champ prenom ne doit pas etre au dela de 30 caracteres.");
            return;
        }

        setWait(false);

        console.log("userUpdate", userUpdate);

        user_services.updateUser(updateId, userUpdate)
            .then((res) => {
                console.log("getuserUpdate : ", res.data)
                if (res.status === 201) {
                    setState((current) => current + 1)
                    navigate("/list/user")
                    toast.success("Utilisateur modifier avec succes.")
                    // Fermer la modal
                    document.getElementById('modal-edit').classList.remove('show');
                    document.getElementById('modal-edit').setAttribute('aria-hidden', 'true');
                    document.body.classList.remove('modal-open');
                    document.querySelector('.modal-backdrop').remove();
                }
                setWait(true);
            })
            // setUserUpdate(res.data[0])
            .catch((err) => {
                setWait(true);
                console.log(err)
                if (err.response) {

                    if (err.response.status === 422) {

                        if (err.response.data.error.role_id === "exists") {
                            toast.error("Ce role n'existe pas.")
                        }
                    }

                    if (err.response.status === 423) {
                        toast.error("Cette email a deja ete utiliser , essayer une autre!")
                    }
                    if (err.response.status === 424) {
                        toast.error("Ce numero a deja ete utiliser , essayer un autre!")
                    }
                    if (err.response.status === 404) {
                        toast.error("Utilisateur introuvable.")
                    }

                    if (err.response.status === 406) {
                        toast.error("Vous n'etes pas autoriser a effectuer cette action.")
                    }

                    if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application.")
                    }

                }
            })
    }

    // toggle user status
    const changeUserStatus = (id) => {
        user_services.toggleUserStatus(id)
            .then(res => {
                console.log('toggleStatust : ', res);
                if (res.data) {
                    setState((current) => current + 1)
                    toast.success("status modifier")
                    // Fermer la modal
                    document.getElementById('modal-detail').classList.remove('show');
                    document.getElementById('modal-detail').setAttribute('aria-hidden', 'true');
                    document.body.classList.remove('modal-open');
                    document.querySelector('.modal-backdrop').remove();
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 203) {
                        toast.error("Vous ne pouvez pas désactivé ou activé cet utilisateur")
                    }
                }
                console.error("err : ", err);
            });
    };
    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Gestion des utilisateurs</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item link"><a onClick={list_user}>Utilisateurs</a></li>
                                    <li className="breadcrumb-item active">Liste des utilisateurs</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-list mr-2 text-light titleIcon"></i> Liste des utilisateurs</h3>
                                        <button className="btn btn-light text-success shadow btn-xs" onClick={add_user} title="Ajouter un utilisateur" style={{ position: 'relative', float: 'right' }}>
                                            <i className="mr-1 fas fa-plus" />
                                            Ajouter
                                        </button>
                                    </div>

                                    <div className="card-body">
                                        <form className="filtre align-items-center d-flex justify-content-between flex-wrap">
                                            <div>
                                                <label htmlFor="orderOption"> Trier par..</label>
                                                <select className="optionSelect" value={sortOrder} name={sortOrder} onChange={(e) => setSortOrder(e.target.value)} id='orderOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="asc">Ordre croissant</option>
                                                    <option value="desc">Ordre decroissant</option>
                                                </select>
                                            </div>

                                            <div>
                                                <label htmlFor="filterOption"> Filtrer par..</label>
                                                <select className="optionSelect ml-2" value={sortFilter} name={sortFilter} onChange={(e) => setSortFilter(e.target.value)} id='filterOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="nom">Nom</option>
                                                    <option value="prenom">Prenom</option>
                                                    <option value="email">Email</option>
                                                </select>
                                            </div>

                                            {/* <div>
                                                <label htmlFor="statusOption"> Status..</label>
                                                <select className="input ml-2" value={sortStatus} name={sortStatus} onChange={(e) => setSortStatus(e.target.value)} id='statusOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="1">Actif</option>
                                                    <option value="0">Inactif</option>
                                                </select>
                                            </div> */}

                                            <div>
                                                <label htmlFor="genderOption">Sexe..</label>
                                                <select className="optionSelect" value={sortGender} name={sortGender} onChange={(e) => setSortGender(e.target.value)} id='genderOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="masculin">Masculin</option>
                                                    <option value="feminin">Feminin</option>
                                                </select>
                                            </div>
                                            <div className="">
                                                <input
                                                    className='search'
                                                    type="text"
                                                    placeholder="Rechercher..."
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </form>
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            striped
                                            highlightOnHover
                                            customStyles={customStyles}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* modals */}
            {/* update user modal */}
            <div className="modal fade" id="modal-edit">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Modification de l'utilisateur  : <strong className='ml-2 capitalize'>{userUpdate.nom + " " + userUpdate.prenom}</strong> </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form className="row form" onSubmit={onSubmit}>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row ">
                                        <p className=''>
                                            Veuillez vérifier le formulaire et mettre a jour les informations de l'utilisateur pour assurer leurs exactitude.
                                        </p>
                                        <div className="col-md-6">
                                            <label htmlFor="nom" className="form-label">
                                                Nom
                                            </label>
                                            <input
                                                type="text"
                                                name="nom"
                                                className="form-control no-focus-outline mb-2"
                                                id="nom" value={userUpdate.nom} onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="prenom" className="form-label">
                                                Prenom
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control no-focus-outline mb-2"
                                                id="prenom"
                                                name="prenom" value={userUpdate.prenom} onChange={onChange}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="numeroTel" className="form-label">
                                                Telephone
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control no-focus-outline mb-2"
                                                id="numeroTel"
                                                name="numeroTel" value={userUpdate.numeroTel} onChange={onChange}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="sexe">
                                                Sexe
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <select className="form-control mb-2" id='sexe' name='sexe' onChange={onChange}>
                                                <option value="" className='text-info' disabled selected>Selectionner votre sexe</option>
                                                <option value="masculin">Masculin</option>
                                                <option value="feminin">Feminin</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="roleId">
                                                Role
                                            </label>

                                            <select className="form-control capitalize" name='roleId' id='roleId' onChange={onChange}>
                                                <option value="" className='bg-success' selected defaultValue>Sélectionner le rôle</option>
                                                {
                                                    roles.map((item, index) => (
                                                        <option key={index} value={item.id} className='capitalize'>
                                                            {item.role}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className='fas fa-times f-3 mr-1'></i>Fermer</button>
                                        <div className="d-flex">
                                            {/* <button type="reset" className="btn btn-secondary btn-sm mr-2" ><i className='fas fa-undo mr-1 f-3' aria-hidden="true" ></i>Annuler</button> */}

                                            {
                                                wait ?
                                                    <button type="submit" className="btn btn-warning btn-sm" title='Modifier'> <i className='fas fa-edit mr-1 f-3'></i>Modifier</button>
                                                    :
                                                    <button className="btn btn-warning btn-sm"> <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Modification..</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* user detail modal */}
            <div className="modal fade" id="modal-detail">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-sm">Déctail de l'utilisateur  : <strong className='ml-2 taille capitalize'> {user.nom + " " + user.prenom}</strong> </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button></div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-md-7 shadow align-items-center justify-content-center">
                                        <div className="logo text-center  px-2 py-2">
                                            <img src={user.sexe === "masculin" ? logom : user.sexe === "feminin" ? logow : ""} alt="profileUser" width="300px" height="200px" />
                                        </div>

                                    </div>

                                    <div className="col-md-5 infoDetail">
                                        {/* <div className="texte ">Bonjours tous le monda , c'est</div> */}
                                        <div className="fw-bold name mt-2"><h4><strong className='taille capitalize shadow px-2 py-2'>{user.nom + " " + user.prenom}</strong></h4></div>

                                        <div className="mt-3"><i className='fas fa-envelope mr-2 ml-2'></i>{user.email}</div>

                                        <div className="mt-3"><i className='fas fa-phone mr-2 ml-2'></i> {user.numeroTel}</div>

                                        <div className="mt-3">
                                            {
                                                user.sexe === 'masculin' ? <i className="fas mr-2 ml-2 fa-mars"></i> :
                                                    user.sexe === 'feminin' ? <i className="fas mr-2 ml-2 fa-venus"></i> : ''
                                            }
                                            {user.sexe}
                                        </div>
                                        <div className="mt-3 ml-2"><span className={user.statut === true ? "badge badge-success" : "badge badge-danger"}>{user.statut === true ? "Activé" : "Désactivé"}</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer  justify-content-between">
                            <button className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className="fas fa-times mr-1"></i>Fermer</button>
                            <div className="btn">
                                <button className={user.statut === true ? "btn btn-danger btn-sm" : "btn btn-success btn-sm"} onClick={() => changeUserStatus(user.id)} title={user.statut === false ? "Activé l'utilisateur" : "Désactivé l'utilisateur"}>
                                    {user.statut === true ? "Désactivé" : "Activé"}
                                </button>
                                <button type="button" className="btn btn-warning btn-sm ml-2" title="Modifier les informations de l'utilisateur" data-dismiss="modal" data-toggle="modal" data-target="#modal-edit" onClick={() => setUpdateId(user.id)}>
                                    <i className="fas fa-edit mr-1" title="Modifier les info l'utilisateur"></i>
                                    Modifier
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListUser;