import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { company_services } from '../../services/company_services';
import toast from 'react-hot-toast';

const AddCompany = () => {
    const navigate = useNavigate();
    // navigation 
    const home = () => {
        navigate('/');
    };
    const add_company = () => {
        navigate('/add/company');
    };
    const list_company = () => {
        navigate('/list/company');
    };

    // variable declaration 
    const [commercial, setCommercial] = useState([])
    const [typeIntervention, setTypeIntervention] = useState([])
    const [activitie, setActivitie] = useState([])
    const [activityAdd, setActivityAdd] = useState("")
    const [wait, setWait] = useState(true);
    const [waitAdd, setWaitAdd] = useState(true);
    const [companie, setCompanie] = useState([])
    const [interventionIndex, setInterventionIndex] = useState();
    const [state, setState] = useState(0)

    const interventionTexts = {
        "1": 'Limite (En nombre de visite)',
        "2": 'Limite (En kg)',
        default: 'Limite'
    };


    const onChange = (e) => {
        setCompanie({
            ...companie,
            [e.target.name]: e.target.value
        })
    }
    const onChangeActivity = (e) => {
        setActivityAdd({
            ...activityAdd,
            [e.target.name]: e.target.value
        })
    }

    const interventionRoleChange = (e) => {
        const index = e.target.selectedIndex;
        setInterventionIndex(index);
        onChange(e);
    };
    console.log('activityAdd :: ', activityAdd);

    // console.log("interventionIndexRes : ", interventionIndex);
    // console.log("companie : ", companie);
    const handleActivity = (e) => {
        e.preventDefault();

        if (
            !activityAdd.activite
        ) {
            toast.error("Le champ est obligatoir.");
            return;
        }

        setWaitAdd(false);

        company_services.addActivity(activityAdd)
            .then((res) => {
                console.log("activityAddRes", res);
                if (res.status === 201) {
                    setState((current) => current + 1)
                    navigate("/add/company");
                    toast.success("Secteur d'activité ajoutée avec succès.");
                    // Fermer la modal
                    document.getElementById('modal-add').classList.remove('show');
                    document.getElementById('modal-add').setAttribute('aria-hidden', 'true');
                    document.body.classList.remove('modal-open');
                    document.querySelector('.modal-backdrop').remove();
                    setActivityAdd({
                        activite: "",
                    });
                    setWaitAdd(true);
                }
            })
            .catch((err) => {
                setWaitAdd(true);
                console.log("err : ", err);
                if (err.response) {
                    if (err.response.data.error.role_id) {
                        toast.error("Ce role n'existe pas")
                    }
                }
            });
    }
    const handleCompanie = (e) => {
        e.preventDefault();

        if (
            !companie.nom ||
            !companie.nomBoss ||
            !companie.numeroTel ||
            !companie.codeCommercial ||
            !companie.activiteId ||
            !companie.typeInterventionId ||
            !companie.limite ||
            !companie.localisation
        ) {
            toast.error("Veuillez remplir tous les champs obligatoires!");
            return;
        }

        if (companie.numeroTel && companie.numeroTel.length < 9) {
            toast.error("Le numero de telephone doit contenir au moins 9 chiffres.");
            return;
        }

        if (companie.nomBoss && companie.nomBoss.length > 50) {
            toast.error("Le champ nom du proprietaire ne doit pas etre au dela de 50 caracteres.");
            return;
        }

        setWait(false);

        // console.log('companie : ', companie);

        company_services.addCompany(companie)
            .then((res) => {
                console.log("companieRes : ", res);
                navigate("/list/company");
                toast.success("Structures ajoutée avec succès.");
                setWait(true);
            })
            .catch((err) => {
                setWait(true);
                console.log("err : ", err);
                if (err.response) {
                    if (err.response.status === 10) {
                        toast.error("Cette email a deja ete utiliser , essayer une autre!")
                    }
                }
            });
    }

    // get all commercial function
    useEffect(() => {
        company_services.getAllCommercial()
            .then((res) => {
                // console.log("commercialRes : ", res.data.commercial)
                setCommercial(res.data.commercial)
            })
            .catch((err) => console.log(err))
    }, [])
    // get all Type Intervention function
    useEffect(() => {
        company_services.getAllTypeIntervention()
            .then((res) => {
                console.log("typeInterventionRes : ", res.data.typeIntervention)
                setTypeIntervention(res.data.typeIntervention)
            })
            .catch((err) => console.log(err))
    }, [])

    // get all activites sertor function
    useEffect(() => {
        company_services.getAllActivities()
            .then((res) => {
                // console.log("activitesRes : ", res.data.activites)
                setActivitie(res.data.activites)
            })
            .catch((err) => console.log(err))
    }, [state])
    // console.log("commercial : ", commercial)
    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Gestion des structures</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item link"><a onClick={add_company}>Structures</a></li>
                                    <li className="breadcrumb-item active">Ajouter une structures</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title"><i className="fas fa-plus mr-2 text-white titleIcon"></i>Ajouter une structure</h3>

                                            <button className="btn btn-light text-success shadow btn-xs" onClick={list_company} title="Liste des structures" style={{ position: 'relative', float: 'right' }}>
                                                <i className="mr-1 fas fa-list-ul" />
                                                Liste
                                            </button>
                                        </div>

                                        <div className="card-body">

                                            <form className="row form" onSubmit={handleCompanie}>
                                                <p className='fs-4'>Veuillez remplir les champs suivants pour crer une structure.
                                                    Les champs marqués d'un astérisque <span className="text-danger taille_etoile">(*)</span> sont obligatoires.
                                                </p>
                                                <div className="col-md-6">
                                                    <label htmlFor="nom" className="form-label" >
                                                        Nom de la structure
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="nom"
                                                        className="form-control no-focus-outline mb-2"
                                                        id="nom"
                                                        autoComplete='off' value={commercial.nom} onChange={onChange}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="nomBoss" className="form-label">
                                                        Nom du responsable
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control no-focus-outline mb-2"
                                                        id="nomBoss" name="nomBoss" autoComplete='off'
                                                        value={commercial.nomBoss} onChange={onChange}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="localisation" className="form-label">
                                                        Localisation
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control no-focus-outline mb-2" id="localisation"
                                                        name="localisation"
                                                        value={commercial.localisation} onChange={onChange}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="numeroTel" className="form-label">
                                                        Telephone
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control no-focus-outline mb-2"
                                                        id="numeroTel"
                                                        name="numeroTel"
                                                        value={commercial.numeroTel} onChange={onChange}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="typeInterventionId">
                                                        Type d'intervention
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <select className="form-control mb-2 capitalize" name='typeInterventionId' id='typeInterventionId' onChange={onChange}>
                                                        <option value="" disabled selected>Sélectionner le type d'intervention </option>
                                                        {
                                                            typeIntervention.map((item, index) => (
                                                                <option className='capitalize' key={index} value={item.id}>
                                                                    {item.type}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="limite" className="form-label">
                                                        {/* {interventionIndex === 1 ? "Limite(En nombre de visite)" : interventionIndex === 2 ?  "Limite(En kg)" : "Limite"}  */}
                                                        {interventionTexts[interventionIndex] || interventionTexts.default}
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control no-focus-outline mb-2"
                                                        id="limite"
                                                        name="limite"
                                                        value={commercial.limite2} onChange={onChange}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="codeCommercial">
                                                        Commercial
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <select className="form-control mb-2 capitalize" name='codeCommercial' id='codeCommercial' onChange={onChange}>
                                                        <option value="" className='text-success' disabled selected>Cliquer pour sélectionner le commercial </option>
                                                        {
                                                            commercial.map((item, index) => (
                                                                <option className='capitalize' key={index} value={item.code}>
                                                                    {item.nom + " " + item.prenom}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="activiteId">
                                                        Secteur d'activité
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <select className="form-control mb-2 capitalize" name='activiteId' id='activiteId' onChange={onChange} >
                                                        <option value="" className='text-dark' disabled selected>Cliquer pour sélectionner le secteur d'activite </option>
                                                        {
                                                            activitie.map((item, index) => (
                                                                <option className='capitalize' key={index} value={item.id}>
                                                                    {item.domaine}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                {/* <div className="col-md-12">
                                                    <label class="custom-file-label" for="logo">Importer le logo</label>
                                                    <input type="file" name="logo" value={commercial.logo} onChange={onChange} className="custom-file-input no-focus-outline mb-2" id="logo" />
                                                </div> */}

                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-between ">
                                                        <div className="btn1">
                                                            <button type='button' data-toggle="modal" data-target="#modal-add" title="Ajouter un secteur d'activité" className="btn btn-info btn-sm mr-2"><i className='fas fa-plus mr-1 f-3'></i>Ajouter une activité</button>
                                                        </div>
                                                        <div className="btn2">
                                                            <button type="reset" className="btn btn-secondary btn-sm mr-2"><i className='fas fa-undo mr-1 f-3'></i>Annuler</button>
                                                            {
                                                                wait ?
                                                                    <button className="btn btn-success btn-sm"> <i className='fas fa-plus mr-1 f-3'></i>Enregistrer</button>
                                                                    :
                                                                    <button className="btn btn-success btn-sm"><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Enregistrement..</button>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>

                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* add activity  modal */}
            <div className="modal fade" id="modal-add">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Ajouter un secteur d'activité</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form className="row form" onSubmit={handleActivity}>
                            <div className="modal-body">
                                <div className="container">
                                    <input type="text" name='activite' value={activityAdd.activite}
                                        onChange={onChangeActivity}
                                        placeholder="Entrer le secteur d'activité" className="form-control" />
                                    <div className="d-flex justify-content-between mt-2">
                                        <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className='fas fa-times f-3 mr-1'></i>Fermer</button>
                                        <div className="d-flex">
                                            {
                                                waitAdd ?
                                                    <button type="submit" className="btn btn-success btn-sm" title='Enregistrer'> <i className='fas fa-plus mr-1 f-3'></i>Enregistrer</button>
                                                    :
                                                    <button className="btn btn-succes btn-sm"> <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Enregistrement..</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCompany;