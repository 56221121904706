import './App.css';
import Public from './routes/fidelis/Public';
import AuthGuuard from './_helpers/AuthGuuard';
import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Auth from './routes/auth/Auth';
import { Toaster } from 'react-hot-toast';
import Company from './routes/fidelis/Company';
import AuthGuuardCompany from './_helpers/AuthGuuardCompany';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Toaster />
        <Routes>
          <Route path="/*" element={
           <AuthGuuard>
           <Public />
         </AuthGuuard>
          } />
          <Route path="/dashboad/*" element={
            <AuthGuuard>
              <Public />
            </AuthGuuard>
          } />
          <Route path="/company/*" element={
            <AuthGuuardCompany>
              <Company />
            </AuthGuuardCompany>
          } />
          <Route path="/auth/*" element={<Auth />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
