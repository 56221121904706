/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './login.css';
import logo from '../../assets/login/logo-removebg-preview (1).png';
import { useNavigate } from 'react-router-dom';
import { account_service } from '../../services/account_service';
import { authentification } from '../../services/authentification';
import toast from 'react-hot-toast';
import swal from 'sweetalert';

const Login = () => {
    const Navigate = useNavigate()
    const [wait, setWait] = useState(true);

    const [credential, setCredential] = useState({
        email: "",
        password: ""
    })

    // console.log("credential : ", credential);

    const onChange = (e) => {
        setCredential({
            ...credential,
            [e.target.name]: e.target.value
        })

    }

    const handleLogin = (e) => {
        e.preventDefault();
        if (credential.email === "" || credential.password === "") {
            toast.error("Veuillez remplir tous les champs !")
            return;
        }

        if (credential.email && !/\S+@\S+\.\S+/.test(credential.email)) {
            toast.error("Veillez entrer une adresse email valide.");
            return;
        }
        setWait(false);

        authentification.login(credential)
            .then((res) => {
                const role = (res.data.role)
                const token = (res.data.token)
                // console.log("Res : ", res);
                // console.log("tokenRes : ", token);

                Navigate('/dashboad');
                //save the token in local storage
                account_service.saveToken(token)
                localStorage.setItem("role", role)
            })
            .catch((err) => {
                setWait(true)

                console.log("err : ", err);

                if (err.response) {

                    if (err.response.status === 404 || err.response.status === 403) {

                        toast.error("Email ou mot de passe incorrect.");

                    } else if (err.response.status === 408) {

                        toast.error("Délai dépassé par la requête")
                    } else if (err.response.status === 402) {
                        swal({
                            title: "Votre compte a été bloqué , veillez contacter l'administrateur ",
                            icon: "error",
                            button: "Fermer",
                        });
                    } else if (err.response.status === 503) {

                        toast.error("Service non accessible de façon temporaire ,en cours de maintenance")

                    } else if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application")
                    }

                }
            })

    }
    return (
        // <div class="login-page">

        //     <form className="login-form" onSubmit={handleLogin}>
        //         <div>
        //             <input className='input-login' type="email" name='email' value={credential.email} onChange={onChange} placeholder="Adresse enail" /> <br />
        //             <input className='input-login' type="password" name='password' value={credential.password} onChange={onChange} placeholder="Mot de passe" />
        //         </div>

        //         <div className="InputBox">
        //             {
        //                 wait ?
        //                     <input className='input-login' type="submit" value='Se connecter' /> :
        //                     <button>
        //                         <span className="spinner-border spinner-border-sm text-primary" aria-hidden="true"></span>
        //                         <span role="status">Vérification...</span>
        //                     </button>
        //             }
        //         </div>
        //     </form>
        // </div>
        <div>
            <div class="login-page">
                <form class="login-formm" onSubmit={handleLogin}>
                    <img className='logo-f' src={logo} alt="logo fidelys" />
                    <h4 className='title'>Se connecter</h4>

                    <div>
                        <input className='input-login' type="email" name='email' value={credential.email} onChange={onChange} placeholder="Adresse enail" /> <br />
                        <input className='input-login' type="password" name='password' value={credential.password} onChange={onChange} placeholder="Mot de passe" />
                    </div>
                    {
                        wait ?
                            <button className='button'>
                                Se connecter
                            </button>
                            :
                            <button className="button disabled">
                                <span className="spinner-border  spinner-border-sm mr-1" aria-hidden="true"></span>
                                <span role="status">Vérification...</span>
                            </button>
                    }
                </form>
            </div>
        </div>
    );
};

export default Login;