import React, { useEffect } from 'react';
import { account_service } from '../services/account_service';
import { Navigate, useNavigate } from 'react-router-dom';

const AuthGuuard = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!account_service.isLogged() || !token) {
      navigate('/auth/login');
    }
  }, [token, navigate]);

  return children;
};

export default AuthGuuard;