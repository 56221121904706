/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './dashboad.css'
import AdminDashboad from './AdminDashboad';
import CommercialDashboad from './CommercialDashboad';

const Dashboar = () => {
    const role = localStorage.getItem("role")

    return (
        <div>
            {
                role === "admin" ?
                    <AdminDashboad />
                    :
                    <CommercialDashboad/>
            }
               
        </div>
    );
};

export default Dashboar;