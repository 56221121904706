/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo-fidelys.jpeg';
import userProfile from '../../assets/img/4.jpg';
const Sidebar = () => {
  // declaration
  const navigate = useNavigate();

  // get user role and name of connected user 
  const role = localStorage.getItem("role")
  const connectedUser = localStorage.getItem("connectedUser")

  // navigation 
  const list_user = () => {
    navigate('/list/user');
  };
  const add_user = () => {
    navigate('/add/user');
  };
  const list_company = () => {
    navigate('/list/company');
  };
  const add_company = () => {
    navigate('/add/company');
  };

  const home = () => {
    navigate('/dashboad');
  };



  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a onClick={home} className="brand-link link">
          <span className="brand-text font-weight-light">
            <img src={logo ? logo : ""} alt="Fidelys Logo" className='align-items-center logo-fidelys' />
          </span>
        </a>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img src={userProfile} className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
              <a  className="d-block link capitalize"><strong>{connectedUser ? connectedUser : "" } [] {role ? role : ""}</strong></a>
            </div>
          </div>
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

              <li className="nav-item has-treeview menu-open">
                <a onClick={home} className="nav-link link active">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Tabeau de bord
                  </p>
                </a>
              </li>
              {
                role === "admin" ?
                  (
                    <>
                      <li className="nav-header">GESTION DES UTILISATEURS</li>
                      <li className="nav-item has-treeview">
                        <a className="nav-link">
                          <i className="nav-icon fas fa-users" />
                          <p>
                            Utilisateurs
                            <i className="fas fa-angle-left right" />
                          </p>
                        </a>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <a onClick={add_user} className="nav-link link">
                              <i className="fas fa-plus nav-icon" />
                              <p>Ajouter un utilisateur</p>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a onClick={list_user} className="nav-link link">
                              <i className="fas fa-list nav-icon" />
                              <p>Liste de utilisateurs</p>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-header">GESTION DES STRUCTURES</li>
                      <li className="nav-item has-treeview">
                        <a className="nav-link">
                          <i className="nav-icon fas fa-store" />
                          <p>
                            Structures
                            <i className="right fas fa-angle-left" />
                          </p>
                        </a>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <a onClick={add_company} className="nav-link link" >
                              <i className="fas fa-plus nav-icon" />
                              <p>Ajouter une structures</p>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a onClick={list_company} className="nav-link link">
                              <i className="fas fa-list nav-icon" />
                              <p>Liste des structures</p>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  )
                  :
                  (
                    <>
                      <li className="nav-header">GESTION DES STRUCTURES</li>
                      <li className="nav-item has-treeview">
                        <a className="nav-link">
                          <i className="nav-icon fas fa-store" />
                          <p>
                            Structures
                            <i className="right fas fa-angle-left" />
                          </p>
                        </a>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <a onClick={add_company} className="nav-link link" >
                              <i className="fas fa-plus nav-icon" />
                              <p>Ajouter une structures</p>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a onClick={list_company} className="nav-link link">
                              <i className="fas fa-list nav-icon" />
                              <p>Liste des structures</p>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  )
              }
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;