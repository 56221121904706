/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { user_services } from '../../services/user_services';
import toast from 'react-hot-toast';

const AddUser = () => {
    const navigate = useNavigate();
    // navigation 
    const home = () => {
        navigate('/');
    };
    const add_user = () => {
        navigate('/add/user');
    };
    const list_user = () => {
        navigate('/list/user');
    };
    // vrariables declarations

    const [wait, setWait] = useState(true);

    const [user, setUser] = useState([])

    const [role, setRoles] = useState([])

    // add user function
    const onChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }
    console.log("userrr : ", user);
    const handleUser = (e) => {
        e.preventDefault();

        if (
            !user.nom ||
            !user.prenom ||
            !user.email ||
            !user.numeroTel ||
            !user.motDePasse ||
            !user.password_confirmation
        ) {
            toast.error("Veuillez remplir tous les champs obligatoires!");
            return;
        }

        if (user.motDePasse !== user.password_confirmation) {
            toast.error("Veillez confirmer les mots de passe.");
            return;
        } else if (user.motDePasse && user.motDePasse.length < 4) {
            toast.error("Le mot de passe doit contenir au moins 4 caractères.");
            return;
        }else if (user.motDePasse && user.motDePasse.length > 8) {
            toast.error("Le mot de passe ne doit pas depasser 8 caractères.");
            return;
        }

        if (user.numeroTel && user.numeroTel.length !== 9) {
            toast.error("Le numero de telephone doit contenir 9 chiffres.");
            return;
        }

        if (user.nom && user.nom.length > 50) {
            toast.error("Le champ nom ne doit pas etre au dela de 50 caracteres.");
            return;
        }

        if (user.prenom && user.prenom.length > 50) {
            toast.error("Le champ prenom ne doit pas etre au dela de 50 caracteres.");
            return;
        }

        if (user.email && !/\S+@\S+\.\S+/.test(user.email)) {
            toast.error("Veillez entrer une adresse email valide.");
            return;
        }

        setWait(false);

        console.log('user : ', user);

        user_services.addUser(user)
            .then((res) => {
                console.log("userRes :", res);
                console.log("user :", user);
                navigate("/list/user");
                toast.success("Utilisateur ajouté avec succès.");
                setWait(true);
            })
            .catch((err) => {
                setWait(true);
                console.log("err : ", err);
                if (err.response) {
                    if (err.response.data.error.email) {
                        toast.error("Cette email a deja ete utiliser , essayer une autre!")
                    }

                    if (err.response.data.error.phone) {
                        toast.error("Ce numero de telephone a deja ete utiliser , essayer un autre!")
                    }

                    if (err.response.data.error.role_id) {
                        toast.error("Ce role n'existe pas")
                    }
                }
            });
    }

    // get all roles
    useEffect(() => {
        user_services.getRole()
            .then((res) => {
                console.log("role : ", res);
                setRoles(res.data.roles)
            })
            .catch((err) => console.log(err))
    }, []);

    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Gestion des utilisateurs</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item link"><a onClick={add_user}>Utilisateurs</a></li>
                                    <li className="breadcrumb-item active">Ajouter un utilisateur</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title"><i className="fas fa-plus mr-2 text-white titleIcon"></i>Ajouter un utilisateur</h3>

                                            <button className="btn btn-light text-success shadow btn-xs" onClick={list_user} title="Liste des utilisateurs" style={{ position: 'relative', float: 'right' }}>
                                                <i className="mr-1 fas fa-list-ul" />
                                                Liste
                                            </button>
                                        </div>

                                        <div className="card-body">

                                            <form className="row form" onSubmit={handleUser}>
                                                <p className='fs-4'>Veuillez remplir les champs suivants pour ajouter un utilisateur.
                                                    Les champs marqués d'un astérisque <span className="text-danger taille_etoile">(*)</span> sont obligatoires.
                                                </p>
                                                <div className="col-md-6">
                                                    <label htmlFor="nom" className="form-label">
                                                        Nom
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="nom"
                                                        className="form-control no-focus-outline mb-2"
                                                        id="nom"
                                                        value={user.nom} onChange={onChange} autoComplete='off'
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="prenom" className="form-label">
                                                        Prenom
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control no-focus-outline mb-2"
                                                        id="prenom" value={user.prenom} onChange={onChange} name="prenom" autoComplete='off'
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="email" className="form-label">
                                                        Email
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control no-focus-outline mb-2" id="email"
                                                        name="email" value={user.email} onChange={onChange} autoComplete='off'
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="numeroTel" className="form-label">
                                                        Telephone
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control no-focus-outline mb-2"
                                                        id="numeroTel"
                                                        name="numeroTel" value={user.numeroTel} onChange={onChange} autoComplete='off'
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="motDePasse" className="form-label">
                                                        Mot de passe
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="password" maxLength="8"
                                                        className="form-control no-focus-outline mb-2"
                                                        id="motDePasse"
                                                        name="motDePasse" value={user.motDePasse} onChange={onChange} autoComplete='off'
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="password_confirmation" className="form-label">
                                                        Confirmer le mot de passe
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <input
                                                        type="password" maxLength="8"
                                                        className="form-control no-focus-outline"
                                                        id="password_confirmation"
                                                        name="password_confirmation" value={user.password_confirmation} onChange={onChange} autoComplete='off'
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="sexe">
                                                        Sexe
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <select className="form-control mb-2" id='sexe' name='sexe' onChange={onChange} autoComplete='off' >
                                                        <option value="" className='text-info' disabled selected>Selectionner votre sexe</option>
                                                        <option value="masculin">Masculin</option>
                                                        <option value="feminin">Feminin</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-6">
                                                    <label htmlFor="roleId">
                                                        Role
                                                        <span className="text-danger taille_etoile">*</span>
                                                    </label>
                                                    <select className="form-control capitalize" name='roleId' id='roleId'  onChange={onChange} autoComplete='off' >
                                                        <option value="" className='text-dark' disabled selected>Cliquer pour sélectionner le rôle </option>
                                                        {
                                                            role.map((item, index) => (
                                                                <option className='capitalize' key={index} value={item.id}>
                                                                    {item.role}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>


                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-between mt-3">
                                                            <button type="reset" className="btn btn-secondary btn-sm mr-2"><i className='fas fa-undo mr-1 f-3'></i>Annuler</button>
                                                            {
                                                                wait ?
                                                                    <button className="btn btn-success btn-sm"> <i className='fas fa-plus mr-1 f-3'></i>Enregistrer</button>
                                                                    :
                                                                    <button className="btn btn-success btn-sm"><span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Enregistrement..</button>
                                                            }
                                                        </div>
                                                    </div>

                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AddUser;