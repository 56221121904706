import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AddCompany, AddUser, Dashboar, Layout, ListCompany, ListUser, Profile, Unauthorized } from '../../pages/public';
import Sidebar from '../../components/sidebar/Sidebar';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
const PrivateRoute = ({ element, allowedRoles }) => {
    const role = localStorage.getItem("role");
    if (!role) {
        return <Navigate to="/login" />;
    }

    if (!allowedRoles.includes(role)) {
        return <Navigate to="/unauthorized" />;
    }
    return element;
};

const Public = () => {
    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Dashboar />} />
                    <Route path='/list/company' element={<PrivateRoute element={<ListCompany />} allowedRoles={['admin', 'commerciale']} />} />
                    <Route path='/add/company' element={<PrivateRoute element={<AddCompany />} allowedRoles={['admin', 'commerciale']} />} />
                    <Route path='/add/user' element={<PrivateRoute element={<AddUser />} allowedRoles={['admin']} />} />
                    <Route path='/list/user' element={<PrivateRoute element={<ListUser />} allowedRoles={['admin']} />} />
                    <Route path='/dashboard' element={<PrivateRoute element={<Dashboar />} allowedRoles={['admin', 'commerciale']} />} />
                    <Route path='/profil' element={<PrivateRoute element={<Profile />} allowedRoles={['admin', 'commerciale']} />} />
                    <Route path='/unauthorized' element={<Unauthorized />} />
                </Route>
            </Routes>
            <Footer />
        </div>
    );
};

export default Public;