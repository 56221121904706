/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
const Unauthorized = () => {
    // Navigations
    const navigate = useNavigate()
    const home = () => {
        navigate('/dashboad');
    };

    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Unauthorized</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item active">Nom authoriser</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content error-pag-content">
                    <div className="error-page ">
                        <h2 className="error-page-title text-center">VOUS N'ETES PAS  AUTHORISER A ACCEDER A CETTE PARTIE</h2>
                    </div>
                    <p>Cliquer sur le bouton ci-dessous pour retourner a la page d'acceuil </p>
                    <button onClick={home} className="btn btn-info btn-xl link">
                        Acceuil
                    </button>
                </section>
            </div>
        </div>
    );
};

export default Unauthorized;