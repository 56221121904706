import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScanneurCodeQr from '../../pages/qrCodeScanner/ScanneurCodeQr';
import Footer from '../../components/footer/Footer';
import { CompanyDashboad, Layout, ListClient } from '../../pages/public';
import HeaderCompany from '../../components/header/HeaderCompany';
import SidebarCompany from '../../components/sidebar/SidebarCompany';
import ProfileCompany from '../../pages/public/ProfileCompany';

const Company = () => {
    return (
        <div>
            <HeaderCompany />
            <SidebarCompany/>
            <Routes>
                <Route element={<Layout />} >
                    <Route index element={<CompanyDashboad />} />
                    <Route path='/dashboad' element={<CompanyDashboad />} />
                    <Route path='/list/client' element={<ListClient />} />
                    <Route path='/scanner' element={<ScanneurCodeQr />} />
                    <Route path='/profil' element={<ProfileCompany />} />
                </Route>
            </Routes>
            <Footer />

        </div>
    );
};

export default Company;