/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { account_service } from '../../services/account_service';
import "./header.css"
const Header = () => {
  const navigate = useNavigate();
  const name = localStorage.getItem("firstname");

  // navigation 
  const profile = () => {
    navigate('/profil');
  };

  // sweet alert of deconnexion 
  const handleLogout = () => {
    swal({
      title: "Déconnection!",
      text: "Êtes-vous sûr de vouloir vous déconnecter ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          account_service.logout()
          navigate("/auth/login");
          swal("Vous avez été  déconnecter!", {
            icon: "success",
          });
        } else {
          swal("Votre séssion est toujours active!");
        }
      });
  };
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
          </li>
          <li className="nav-item">
            <a title='Voir mon profil' onClick={profile} className="nav-link link">Profil</a>
          </li>
        </ul>
       
        <ul className="navbar-nav ml-auto">
          <a className='deconnexion px-2 py-2 text-white badge badge-success' onClick={handleLogout}>
            <i class="fas fa-sign-out-alt mr-1"></i>Déconnexion
          </a>
        </ul>
      </nav>
    </div>
  );
};

export default Header;