/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo-fidelys.jpeg';
const SidebarCompany = () => {
  const navigate = useNavigate();

  // navigation 
  const list_client = () => {
    navigate('/company/list/client');
  };

  const scan_code = () => {
    navigate('/company/scanner');
  };

  const home = () => {
    navigate('/company/dashboad');
  };
  const profil = () => {
    navigate('/company/profil');
  };
  
  // get name of connected company 
  const nom = localStorage.getItem("nom")

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a onClick={home} className="brand-link link">
          <span className="brand-text font-weight-light">
            <img src={logo} alt="Fidelys Logo" className='align-items-center logo-fidelys' />
          </span>
        </a>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="info">
            <a  onClick={profil} className="d-block link uppercase companyName"><strong>{nom}</strong></a>
            </div>
          </div>
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

              <li className="nav-item has-treeview menu-open">
                <a onClick={home} className="nav-link link active">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Tabeau de bord
                  </p>
                </a>

              </li>
              <li className="nav-header">GESTION DES CLIENTS</li>
              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <i className="nav-icon fas fa-store" />
                  <p>
                    Clients
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a onClick={list_client} className="nav-link link">
                      <i className="fas fa-list nav-icon" />
                      <p>Liste des clients</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={scan_code} className="nav-link link">
                      <i className="fas fa-scanner nav-icon" />
                      <p>Scanner le Qr code</p>
                    </a>
                  </li>
                </ul>
              </li>

            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default SidebarCompany;