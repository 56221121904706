import Axios from "./caller_service.js"

const login = (credential) => {
    return Axios.post('/utilisateur/login', credential)
}
const loginCompany = (credential) => {
    return Axios.post('/structure/login', credential)
}
const updateUserPassword = (credential) => {
    return Axios.put('/utilisateur/edtitMotDePasse/', credential)
}
const profile = () => {
    return Axios.get('/utilisateur/profile/')
}
const profileCompany = () => {
    return Axios.get('/structure/profile/')
}

export const authentification = {
    login,
    updateUserPassword,
    profile,
    loginCompany,
    profileCompany
}