import React from 'react';
import { Layout, Login, LoginCompany } from '../../pages/auth';
import { Route, Routes } from 'react-router-dom';

const Auth = () => {
    return (
        <div>
            <Routes>
                <Route element={<Layout />} >
                    <Route index element={<Login />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/loginCompany' element={<LoginCompany />} />
                </Route>
            </Routes>
        </div>
    );
};

export default Auth;