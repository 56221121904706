/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Footer = () => {
    return (
        <div>
        <footer className="main-footer">
          <strong>Copyright © 2023-2024 <a href="http://allhcorp.com">allhcorp.com</a>.</strong>
          Tout droit reserver.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> beta
          </div>
        </footer>
      </div>
    );
};

export default Footer;