// Style personnalisé pour l'entête

export const customStyles = {
    headRow: {
        style: {
            backgroundColor: '#cdcdcd',
            // backgroundColor: 'gray',
            color: '#000000',
            // color: '#fff',
            minHeight: '40px',
            fontSize: "15px",
            fontWeight: '700'
        },
    },
    rows: {
        style: {
            minHeight: '40px', // Modifier la hauteur minimale des lignes
        },
    },
    tableWrapper: {
        style: {
            overflow: 'auto', // Permettre le défilement si nécessaire
        },
    },
    cells: {
        style: {
            paddingLeft: '1px', // Augmente l'espace à gauche des cellules
            paddingRight: '1px', // Augmente l'espace à droite des cellules
        },
    },
    headCells: {
        style: {
            paddingLeft: '3px', // Augmente l'espace à gauche des cellules d'en-tête
            paddingRight: '3px', // Augmente l'espace à droite des cellules d'en-tête
        },
    },
};