const saveToken = (token) => {
    localStorage.setItem('token', token);
}

const logout = () => {
    localStorage.removeItem('token')
}

const isLogged = () => {
    const token = localStorage.getItem('token');
    return token
}
const getToken = () => {
    const token = localStorage.getItem('token');
    return token;
}

const saveTokenCompany = (tokenCompany) => {
    localStorage.setItem('tokenCompany', tokenCompany);
}

const logoutCompany = () => {
    localStorage.removeItem('tokenCompany')
}

const isLoggedCompany = () => {
    const token = localStorage.getItem('tokenCompany');
    return token
}

const getTokenCompany = () => {
    const token = localStorage.getItem('tokenCompany');
    return token;
}

export const account_service = {
    saveToken,
    logout,
    isLogged,
    getToken,
    getTokenCompany,
    logoutCompany,
    isLoggedCompany,
    saveTokenCompany        
}