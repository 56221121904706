/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logom from "../../assets/user/user/4.jpg";
import logow from "../../assets/user/user/1.jpeg";
import { authentification } from '../../services/authentification';
import toast from 'react-hot-toast';
import swal from 'sweetalert';
const Profile = () => {

    // navigation
    const navigate = useNavigate();
    const home = () => {
        navigate('/dashboad');
    };

    // declarations
    const [wait, setWait] = useState(true);
    const [userPass, setUserPass] = useState([])
    const [userProfile, setUserProfile] = useState([])

    // get user profile
    useEffect(() => {
        authentification.profile()
            .then((res) => {
                console.log("userProfileRes : ", res.data)
                setUserProfile(res.data)
            })
            .catch((err) => console.log(err))
    }, []);

    localStorage.setItem('connectedUser', userProfile.nom);

    // update user password 
    const onChange = (e) => {
        setUserPass({
            ...userPass,
            [e.target.name]: e.target.value
        })
    }

    // console.log("userPass : ", userPass);

    const handleUser = (e) => {
        e.preventDefault();

        if (
            !userPass.holdMotDePasse ||
            !userPass.newMotDePasse ||
            !userPass.verifMotDePasse

        ) {
            toast.error("Veuillez remplir tous les champs!");
            return;
        }

        if (userPass.holdMotDePasse && userPass.holdMotDePasse.length < 4) {
            toast.error("L'ancien mot de passe doit avoir au moins 4 caractères.");
            return;
        } else if (userPass.holdMotDePasse && userPass.holdMotDePasse.length > 8) {
            toast.error("L'ancien mot de passe ne doit pas dépasser 8 caractères.");
            return;
        } else if (userPass.newMotDePasse !== userPass.verifMotDePasse) {
            toast.error("Veillez confirmer le mot de passe.");
            return;
        } else if (userPass.newMotDePasse && userPass.verifMotDePasse.length < 4) {
            toast.error("Le nouveau mot de passe doit avoir au moins 4 caractères.");
            return;
        } else if (userPass.newMotDePasse && userPass.verifMotDePasse.length > 8) {
            toast.error("Le nouveau mot de passe ne doit pas depasser 8 caractères.");
            return;
        }

        setWait(false);

        // console.log('userPass : ', userPass);

        authentification.updateUserPassword(userPass)
            .then((res) => {
                console.log("userPassRes", res);
                navigate("/profil");
                swal({
                    text: "Mot de passe modifier avec succès.",
                    icon: "success",
                    button: "Fermer",
                });
                setWait(true);
            })
            .catch((err) => {
                setWait(true);
                console.log("err : ", err);
                if (err.response) {
                    if (err.response.status === 403) {
                        toast.error("Ancien mot de passe incorrect")
                    } else if (err.response.status === 404) {
                        toast.error("Utilisateur introuvable")
                    } else if (err.response.status === 400) {
                        toast.error("Le nouveau mot de passe doit contenir un chiffre.")
                    } else if (err.response.status === 401) {
                        toast.error("Votre session a expir")
                    } else if (err.response.status === 503) {
                        toast.error("Service non accessible de façon temporaire ,en cours de maintenance")
                    } else if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application")
                    }
                }
            });
    }

    // const formatDate = (isoDateString) => {
    //     const date = new Date(isoDateString);

    //     // Obtenir les parties de la date
    //     const joursSemaine = [
    //         "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"
    //     ];
    //     const moisAnnee = [
    //         "janvier", "février", "mars", "avril", "mai", "juin",
    //         "juillet", "août", "septembre", "octobre", "novembre", "décembre"
    //     ];

    //     const jourSemaine = joursSemaine[date.getDay()];
    //     const jour = date.getDate();
    //     const mois = moisAnnee[date.getMonth()];
    //     const annee = date.getFullYear();
    //     const heures = date.getHours().toString().padStart(2, '0');
    //     const minutes = date.getMinutes().toString().padStart(2, '0');
    //     const secondes = date.getSeconds().toString().padStart(2, '0');

    //     // Construire la chaîne formatée
    //     return `${jourSemaine} ${jour} ${mois} ${annee} à ${heures}:${minutes}:${secondes}`;
    // }

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Profil</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li onClick={home} className="breadcrumb-item link">Acceuil</li>
                                <li className="breadcrumb-item active">Profil</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-6">
                            <div className="card bordure ">
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 480 }}>
                                            <div className="img text-center">
                                                <img src={userProfile.sexe === "masculin" ? logom : userProfile.sexe === "feminin" ? logow : ""}
                                                    height={200} width={300} alt="imageProfile" />
                                            </div>
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span><i className="fas fa-calendar mr-2" />Date de création :  </span>
                                                <span>{userProfile.date}</span>
                                            </div>
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span>
                                                    <i className={userProfile.role === 'admin' ? "fas mr-2 fa-gamepad" : "fas mr-2 mr-2 fa-shield-alt"}></i>
                                                    Role :
                                                </span>
                                                <span className="capitalize">
                                                    {userProfile.role}
                                                </span>
                                            </div>
                                            <hr />
                                            <div className="mt-3 ml-2"><span className={userProfile.statut === true ? "badge badge-success" : "badge badge-danger"}>{userProfile.statut === true ? "Activé" : "Désactivé"}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="col-lg-6">

                            <div className="card bordure ">
                                <div className="card-header p-1 bg-color">
                                    <div className="card-title">
                                        <h4 className='title-card m-0 text-center text-md-left'>
                                            <i class="fa fa-info-circle icon ml-1 mr-2"></i>
                                            Informations personnelles
                                        </h4>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 166 }}>
                                            <div className="info d-flex justify-content-between mt-3">
                                                <span > <i class="fas fa-user mr-2 "></i>Nom :  </span>
                                                <span className='capitalize'>{userProfile.nom + "  " + userProfile.prenom}</span>
                                            </div>
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-3">
                                                <span><i class="fas fa-envelope  mr-2" />Email :  </span>
                                                <span>{userProfile.email}</span>
                                            </div>
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-3">
                                                <span><i class="fas fa-phone  mr-2" />Telephone :  </span>
                                                <span>(+237) {userProfile.numeroTel}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form className="g-2 form container-fluid" onSubmit={handleUser}>
                                <div className="card bordure">
                                    <div className="card-header p-2 bg-color">
                                        <div className="card-title">
                                            <h4 className='title-card m-0 text-center text-md-left'>
                                                <i className="fa fa-key icon ml-1 mr-2"></i>
                                                Modification du mot de passe
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {/* Ancien mot de passe */}
                                            <div className="col-12">
                                                <label htmlFor="holdMotDePasse" className="form-label">
                                                    Ancien mot de passe
                                                    <span className="text-danger taille_etoile">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="holdMotDePasse"
                                                    maxLength="8"
                                                    placeholder="Ancien mot de passe"
                                                    className="form-control no-focus-outline mb-3"
                                                    id="holdMotDePasse"
                                                    autoComplete="off"
                                                    value={userPass.holdMotDePasse}
                                                    onChange={onChange}
                                                />
                                            </div>

                                            {/* Nouveau mot de passe */}
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="newMotDePasse" className="form-label">
                                                    Nouveau mot de passe
                                                    <span className="text-danger taille_etoile">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="newMotDePasse"
                                                    maxLength="8"
                                                    placeholder="Nouveau mot de passe"
                                                    className="form-control no-focus-outline mb-3"
                                                    id="newMotDePasse"
                                                    autoComplete="off"
                                                    value={userPass.newMotDePasse}
                                                    onChange={onChange}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <label htmlFor="verifMotDePasse" className="form-label">
                                                    Retaper le mot de passe
                                                    <span className="text-danger taille_etoile">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="verifMotDePasse"
                                                    maxLength="8"
                                                    placeholder="Retaper le mot de passe"
                                                    className="form-control no-focus-outline mb-3"
                                                    id="verifMotDePasse"
                                                    autoComplete="off"
                                                    value={userPass.verifMotDePasse}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center justify-content-md-start mt-3">
                                            <button type="reset" className="btn btn-secondary btn-sm mr-2">
                                                <i className='fas fa-times mr-1'></i>Annuler
                                            </button>
                                            {
                                                wait ? (
                                                    <button type="submit" className="btn btn-warning btn-sm">
                                                        <i className='fas fa-edit mr-1'></i>Modifier
                                                    </button>
                                                ) : (
                                                    <button type="button" className="btn btn-warning btn-sm" disabled>
                                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Modification...
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </div>
                </div >
            </section >
        </div >
    );
};

export default Profile;