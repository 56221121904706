import Axios from "./caller_service.js"

// user 
const totalUser = () => {
    return Axios.get("/admin/utilisateur/commerciaux")
}
const totalUserEnable = () => {
    return Axios.get("/admin/utilisateur/commerciaux/actives")
}
const totalUserDesable = () => {
    return Axios.get("/admin/utilisateur/commerciaux/desactives")
}
const totalCompany = () => {
    return Axios.get("/admin/structures/")
}
const totalCompanyEnable = () => {
    return Axios.get("/admin/structures/activees")
}
const totalCompanyDesable = () => {
    return Axios.get("/admin/structures/desactivees")
}
const totalPressing = () => {
    return Axios.get("/admin/structures/pressing")
}
const totalHairHouse = () => {
    return Axios.get("/admin/structures/salon")
}

// company 

const totalClient = () => {
    return Axios.get("/structure/nbrClient")
}
const nbrClientAleatoire = () => {
    return Axios.get("/structure/nbrClientAleatoire")
}
const nbrClientFidels = () => {
    return Axios.get("/structure/nbrClientFidels")
}
const customerLoyal = () => {
    return Axios.get("/structure/listClientFidels")
}
const tauxClientFidele = () => {
    return Axios.get("/structure/tauxClientFidele")
}

// commercial 
const bestCommercial = () => {
    return Axios.get("/dashboard/totalGuest")
}
const listCompaniesEnable = () => {
    return Axios.get("/utilisateur/commercial/structure")
}

export const dashboad = {
    // user 
    totalUser,
    totalUserEnable,
    totalUserDesable,
    totalCompany,
    totalCompanyEnable,
    totalCompanyDesable,
    totalPressing,
    totalHairHouse,

    // company 
    totalClient,
    nbrClientFidels,
    nbrClientAleatoire,
    customerLoyal,
    tauxClientFidele,

    // commercial 
    bestCommercial,
    listCompaniesEnable
}