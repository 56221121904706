/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../components/datatable/customStyles';
import QRCode from 'react-qr-code';
import toast from 'react-hot-toast';
import { clients_services } from '../../services/clients_services';
import { toPng } from 'html-to-image';
import swal from 'sweetalert';

const ListClient = () => {
    // Navigations
    const navigate = useNavigate()
    const home = () => {
        navigate('/company/dashboad');
    };
    const list_client = () => {
        navigate('/company/list/client');
    };


    // declaration 
    const qrRef = useRef(null)
    const [wait, setWait] = useState(true)
    const [state, setState] = useState(0)
    const [id, setId] = useState('')
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState({})
    const [clientd, setClientd] = useState({})
    const [updateId, setUpdateId] = useState('')
    const [clientUpdate, setClientUpdate] = useState({})
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [sortGender, setSortGender] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [sortFilter, setSortFilter] = useState('');

    // detail client info
    // console.log("id ------->: ", id);
    useEffect(() => {
        if (id !== '') {
            clients_services.getClient(id)
                .then((res) => {
                    console.log("ClientDetailRes:", res.data.client);
                    setClientd(res.data.client)
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response) {
                        if (err.response.status === 400) {
                            swal({
                                text: "Votre session a expiré, veillez vous reconnecter",
                                icon: "error",
                                button: "Fermer",
                            });
                        }
                        if (err.response.status === 404) {
                            toast("Utilisateur introuvable")
                        }
                        if (err.response.status === 403) {
                            toast.error("Erreur lors de la recuperation du client");
                        }
                        if (err.response.status === 500) {
                            toast.error("Erreur d'exéciution dans l'application , veillez vous reconnecter.")
                        }
                    }
                }
                );
        }
    }, [id]);

    // get all client function
    const getAllClients = () => {
        clients_services.getAllClients()
            .then((res) => {
                console.log("ClientsRes : ", res.data.allClients
                )
                setClients(res.data.allClients)
            })
            .catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 400) {
                        swal({
                            text: "Votre session a expiré, veillez vous reconnecter",
                            icon: "error",
                            button: "Fermer",
                        });
                    }
                    if (err.response.status === 404) {
                        toast("Utilisateur introuvable")
                    }
                    if (err.response.status === 403) {
                        toast.error("Erreur lors de la recuperation des clients");
                    }
                    if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application , veillez vous reconnecter.")
                    }
                }
            }
            )
    }
    console.log("clientsRes : ", clients)

    // Retrieve the clients when the component is mounted and each time the sorting/filtering criteria change
    useEffect(() => {
        getAllClients();
    }, [sortOrder, sortFilter, sortGender, state]);

    // Définir les colonnes du tableau
    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: '60px',
            sortable: true,
        },
        {
            name: 'Nom',
            selector: (row) => row.nom,
            sortable: true,
        },
        {
            name: 'Prénom',
            selector: (row) => row.prenom,
            sortable: true,
        },
        {
            name: 'Localisation',
            selector: (row) => row.localisation,
            sortable: true,
        },
        {
            name: 'Téléphone',
            selector: (row) => row.numeroTel,
            sortable: true,
        },
        {
            name: 'Genre',
            selector: (row) => row.sexe,
            sortable: true,
        },
        {
            name: 'conso.',
            selector: (row) => row.intervention,
            sortable: true,
        },
        // {
        //     name: 'Quanite',
        //     selector: (row) => row.intervention2,
        //     sortable: true,
        // },
        {
            name: 'Actions',
            cell: (row) => (
                <div className='action-container d-flex'>
                    <button className="btn btn-primary btn-xs" title='Voir plus de detail' data-toggle="modal" data-target="#modal-detail" onClick={() => setId(row.id)} ><i className="fas fa-eye " /></button>
                    <button className="btn btn-warning btn-xs mx-3" title='Modifier' data-toggle="modal" data-target="#modal-edit" onClick={() => setUpdateId(row.id)}><i className="fas fa-edit" /></button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // Function to filter and sort client based on current status
    const getClientsSortAdnfilter = () => {
        let clientsFilters = clients

        // Apply gender filter
        if (sortGender) {
            clientsFilters = clientsFilters.filter((clients) => clients.sexe === sortGender);
        }

        // sort clients info
        clientsFilters.sort((a, b) => {
            if (sortOrder === 'desc') {
                if (sortFilter === 'nom') {
                    return b.nom.localeCompare(a.nom);
                } else if (sortFilter === 'prenom') {
                    return b.prenom.localeCompare(a.prenom);
                } else if (sortFilter === 'localisation') {
                    return b.localisation.localeCompare(a.localisation);
                } else if (sortFilter === 'numeroTel') {
                    return b.numeroTel.localeCompare(a.numeroTel);
                }
            } else {
                if (sortFilter === 'nom') {
                    return a.nom.localeCompare(b.nom);
                } else if (sortFilter === 'prenom') {
                    return a.prenom.localeCompare(b.prenom);
                } else if (sortFilter === 'localisation') {
                    return a.localisation.localeCompare(b.localisation);

                } else if (sortFilter === 'numeroTel') {
                    return a.numeroTel.localeCompare(b.numeroTel);
                }
            }
        });

        return clientsFilters
    }

    // Mettre à jour les données filtrées et triées chaque fois que l'un des états change
    useEffect(() => {
        const sortedFilteredClients = getClientsSortAdnfilter();
        setFilteredData(sortedFilteredClients);
    }, [clients, sortOrder, sortFilter, sortGender]);

    // Filtrer les données en fonction du texte de recherche
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        const filtered = getClientsSortAdnfilter().filter(item => {

            return (
                item.nom.toLowerCase().includes(value.toLowerCase()) ||
                item.prenom.toLowerCase().includes(value.toLowerCase()) ||
                item.localisation.toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };

    // add client function
    const onChangeAdd = (e) => {
        setClient({
            ...client,
            [e.target.name]: e.target.value
        })
    }

    console.log("client : ", client);
    const handleClient = (e) => {
        e.preventDefault();

        if (
            !client.nom ||
            !client.numeroTel ||
            !client.localisation ||
            !client.sexe
        ) {
            toast.error("Veuillez remplir tous les champs obligatoires!");
            return;
        }

        if (client.numeroTel && client.numeroTel.length !== 9) {
            toast.error("Le numéro de téléphone doit contenir 9 chiffres.");
            return;
        }

        if (client.nom && client.nom.length > 50) {
            toast.error("Le champ nom ne doit pas etre au dela de 50 caracteres.");
            return;
        }

        if (client.prenom && client.prenom.length > 50) {
            toast.error("Le champ prenom ne doit pas etre au dela de 50 caracteres.");
            return;
        }

        setWait(false);

        clients_services.addClient(client)
            .then((res) => {
                console.log("clientRes :", res);
                if (res.status === 201) {
                    navigate("/company/list/client");
                    setState((current) => current + 1)
                    toast.success("Client ajouté avec succès.");
                    // Fermer la modal
                    document.getElementById('modal-add').classList.remove('show');
                    document.getElementById('modal-add').setAttribute('aria-hidden', 'true');
                    document.body.classList.remove('modal-open');
                    document.querySelector('.modal-backdrop').remove();
                    setClient({
                        nom: "",
                        prenom: "",
                        localisation: "",
                        numeroTel: "",
                        sexe: "",
                    });
                    setWait(true);

                }

            })
            .catch((err) => {
                setWait(true);
                console.log("err : ", err);
                if (err.response) {
                    if (err.response.status === 400) {
                        navigate("/auth/loginCompany");
                        swal({
                            text: "Votre séssion a expiré, veillez vous connecter",
                            icon: "error",
                            button: "Fermer",
                        });
                    }
                    if (err.response.status === 404) {
                        toast("Utilisateur introuvable")
                    }

                    if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application , veillez vous reconnecter.")
                    }
                }
            });
    }

    // update client function
    // console.log("updateId -----> ", updateId)

    useEffect(() => {
        if (updateId !== '') {
            clients_services.getClient(updateId)
                .then((res) => {
                    console.log("companyUpdateRES ::", res.data.client)
                    setClientUpdate(res.data.client)
                })
                .catch((err) => {
                    console.error("err :", err);
                });
        }
    }, [updateId]);


    const onChange = (e) => {
        setClientUpdate({
            ...clientUpdate,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (
            !clientUpdate.nom ||
            !clientUpdate.numeroTel ||
            !clientUpdate.sexe ||
            !clientUpdate.localisation
        ) {
            toast.error("Veuillez remplir tous les champs obligatoires!");
            return;
        }

        if (clientUpdate.numeroTel && clientUpdate.numeroTel.length < 9) {
            toast.error("Le numero de telephone doit contenir au moins 9 chiffres.");
            return;
        }

        if (clientUpdate.nom && clientUpdate.nom.length > 30) {
            toast.error("Le champ nom ne doit pas etre au dela de 30 caracteres.");
            return;
        }

        setWait(false);

        // console.log("updateId::::", updateId);

        clients_services.updateClient(updateId, clientUpdate)
            .then((res) => {
                console.log("getClientUpdate : ", res.data)
                if (res.status === 201) {
                    setState((current) => current + 1)
                    navigate("/company/list/client")
                    toast.success("Structure modifier avec succes.")
                    document.getElementById('modal-edit').classList.remove('show');
                    document.getElementById('modal-edit').setAttribute('aria-hidden', 'true');
                    document.body.classList.remove('modal-open');
                    document.querySelector('.modal-backdrop').remove();
                }
                setWait(true);
            })
            .catch((err) => {
                setWait(true);
                console.log(err)
                if (err.response) {

                    if (err.response.status === 400) {
                        navigate("/auth/loginCompany");
                        swal({
                            text: "Votre séssion a expiré, veillez vous connecter",
                            icon: "error",
                            button: "Fermer",
                        });
                    }
                    if (err.response.status === 403) {
                        toast.error("Le client selectionner n'existe pas.")
                    }
                    if (err.response.status === 404) {
                        toast.error("Utilisateur introuvable.")
                    }

                    if (err.response.status === 405) {
                        toast.error("Erreur lors de la modification de client")
                    }

                    if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application.")
                    }

                }
            })
    }

    // put client into qr code 
    const clientInfo = JSON.stringify({
        Id: clientd.id,
        Nom: clientd.nom,
        Prenom: clientd.prenom,
        Telephone: clientd.numeroTel,
        Localisation: clientd.localisation,
        Intervention1: clientd.intervention1,
        Intervention2: clientd.intervention2,
        Sexe: clientd.sexe,
        DateCreation: clientd.createdAt,
        Structure: clientd.StructureId,
        Bonus: clientd.bonus
    });

    // Function to format the current date and time as 'YYYY-MM-DD_HH-MM'
    const getFormattedDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}_${hours}-${minutes}`;
    };

    // Function to download QR code as image with custom filename
    const downloadQrCode = () => {
        if (qrRef.current === null) {
            return;
        }
        // Format the filename as "GuestName-EventName-DateTime.png"
        const dateTime = getFormattedDateTime(); // Get the current date and time
        const filename = `${clientd.nom}_${clientd.prenom}-${dateTime}.png`;

        toPng(qrRef.current)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = filename; // Set the filename dynamically-
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.error('Error generating image', err);
            });
    };

    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Gestion des clients</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item link"><a onClick={list_client}>Clients</a></li>
                                    <li className="breadcrumb-item active">Liste des clients</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header ">
                                            <h3 className="card-title"><i className="fas fa-list mr-2 text-light titleIcon"></i> Liste des clients</h3>

                                            {/* <strong className='total_entree'>   Total : {clients.length}</strong> */}

                                            <button className="btn btn-light text-success shadow btn-xs" title='Ajouter un client' data-toggle="modal" data-target="#modal-add" style={{ position: 'relative', float: 'right' }}>
                                                <i className="mr-1 fas fa-plus" />
                                                Ajouter
                                            </button>
                                        </div>

                                        <div className="card-body">
                                            <form className="form filtre align-items-center d-flex justify-content-between flex-wrap" >
                                                <div>
                                                    <label htmlFor="orderOption"> Trier par..</label>
                                                    <select className="input" value={sortOrder} name={sortOrder} onChange={(e) => setSortOrder(e.target.value)} id='orderOption'>
                                                        <option value="" selected >---</option>
                                                        <option value="asc">Ordre croissant</option>
                                                        <option value="desc">Ordre decroissant</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <label htmlFor="filterOption"> Filtrer par..</label>
                                                    <select className=" ml-2" value={sortFilter} name={sortFilter} onChange={(e) => setSortFilter(e.target.value)} id='filterOption'>
                                                        <option value="" selected >---</option>
                                                        <option value="nom">Nom</option>
                                                        <option value="prenom">Prenom</option>
                                                        <option value="localisation">Localisation</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <label htmlFor="genderOption">Sexe..</label>
                                                    <select className="" value={sortGender} name={sortGender} onChange={(e) => setSortGender(e.target.value)} id='genderOption'>
                                                        <option value="" selected >---</option>
                                                        <option value="masculin">Masculin</option>
                                                        <option value="feminin">Feminin</option>
                                                    </select>
                                                </div>
                                                <div className="">
                                                    <input
                                                        className='search'
                                                        type="text"
                                                        placeholder="Rechercher..."
                                                        value={searchText}
                                                        onChange={handleSearch}
                                                    />
                                                </div>
                                            </form>
                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                striped
                                                highlightOnHover
                                                customStyles={customStyles}
                                                pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* modals */}
            {/* add client modal */}
            <div className="modal fade" id="modal-add">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Ajouter un client</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form className="row form" onSubmit={handleClient}>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row ">
                                        <p className=''>
                                            Veuillez vérifier le formulaire et mettre a jour les informations de l'utilisateur pour assurer leur exactitude.
                                        </p>
                                        <div className="col-md-6">
                                            <label htmlFor="nom" className="form-label" >
                                                Nom du client
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="nom"
                                                className="form-control no-focus-outline mb-2"
                                                id="nom"
                                                value={clients.nom} onChange={onChangeAdd}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="prenom" className="form-label">
                                                Prenom
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control no-focus-outline mb-2"
                                                id="prenom" name="prenom"
                                                value={client.prenom} onChange={onChangeAdd}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="localisation" className="form-label">
                                                Localisation
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control no-focus-outline mb-2" id="localisation"
                                                name="localisation"
                                                value={client.localisation} onChange={onChangeAdd}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="numeroTel" className="form-label">
                                                Telephone
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control no-focus-outline mb-2"
                                                id="numeroTel"
                                                name="numeroTel"
                                                value={client.numeroTel} onChange={onChangeAdd}
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="sexe">
                                                Sexe
                                            </label>
                                            <select className="form-control mb-2" id='sexe' name='sexe' onChange={onChangeAdd} autoComplete='off' >
                                                <option value="" className='text-success' disabled selected>Selectionner votre sexe</option>
                                                <option value="masculin">Masculin</option>
                                                <option value="feminin">Feminin</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className='fas fa-times f-3 mr-1'></i>Fermer</button>
                                        <div className="d-flex">
                                            {
                                                wait ?
                                                    <button type="submit" className="btn btn-success btn-sm" title='Enregistrer le client'> <i className='fas fa-plus mr-1 f-3'></i>Enregistrer</button>
                                                    :
                                                    <button className="btn btn-success btn-sm"> <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Enregistrement..</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* client detail modal */}
            <div className="modal fade" id="modal-detail">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-sm">Detail du client  : <strong className='ml-2 capitalize'>{clientd.nom + " " + clientd.prenom}</strong> </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button></div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-md-7 shadow text-center qr-container">
                                        <div className="qr-code-container">
                                            <QRCode
                                                className='link qr-code'
                                                ref={qrRef}
                                                onClick={downloadQrCode}
                                                value={clientInfo}
                                                size={256}
                                            />
                                            {/* <QRCode value={jsonData} size={256} /> */}

                                            <button onClick={downloadQrCode} className="bouton-qr" title='Cliquer pour télécharger le Code QR' >
                                                Télécharger le QR Code
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-md-5">
                                        {/* <div className="texte ">Bonjours tous le monda , c'est</div> */}
                                        <div className="fw-bold name mt-2"><h4><strong className='capitalize shadow px-2 py-2'>{clientd.nom + " " + clientd.prenom}</strong></h4></div>


                                        <div className="mt-3"><i className='fas fa-map-marker-alt mr-2 ml-2'></i>{clientd.localisation}</div>

                                        <div className="mt-3"><i className='fas fa-phone mr-2 ml-2'></i> (+237) {clientd.numeroTel} </div>

                                        <div className="mt-3"><i className='fas fa-dumbbell mr-2 ml-2'></i> {clientd.intervention2} <strong>Kg</strong></div>

                                        {/* <div className="mt-3"><i className='fas fa-infinity mr-2 ml-2'></i>{clientd.intervention1}  <strong>Visite(s)</strong></div> */}
                                        <div className="mt-3">
                                            {
                                                clientd.sexe === 'masculin' ? <i className="fas  mr-2 ml-2 fa-mars"></i> :
                                                    clientd.sexe === 'feminin' ? <i className="fas  mr-2 ml-2 fa-venus"></i> : ''
                                            }
                                            {clientd.sexe}
                                        </div>

                                        <div className="mt-3"> Bonus atteint : <strong className='text-success'>{clientd.bonus}</strong></div>
                                        {/* <div className="mt-3 ml-2"><span className={clientd.statut ===  ? "badge badge-success" : "badge badge-danger"}>{clientd.bonus === true ? "Activé" : "Désactivé"}</span></div> */}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer  justify-content-between">
                            <button className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className="fas fa-times mr-1"></i>Fermer</button>
                            <div className="btn">
                                <button type="button" className="btn btn-success btn-sm ml-2" title="Ajouter un nouveau client" data-toggle="modal" data-target="#modal-add" data-dismiss="modal">
                                    <i className="fas fa-plus mr-1" ></i>
                                    Ajouter
                                </button>
                                <button type="button" className="btn btn-warning btn-sm ml-2" title="Modifier les informations du client" data-dismiss="modal" data-toggle="modal" data-target="#modal-edit" onClick={() => setUpdateId(clientd.id)}>
                                    <i className="fas fa-edit mr-1" title="Modifier les info l'utilisateur"></i>
                                    Modifier
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* update client modal */}
            <div className="modal fade" id="modal-edit">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Modification du client  : <strong className='ml-2 capitalize'> {clientUpdate.nom + " " + clientUpdate.prenom}</strong> </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form className="row form" onSubmit={onSubmit}>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row ">
                                        <p className=''>
                                            Veuillez vérifier le formulaire et mettre a jour les informations de l'utilisateur pour assurer leurs exactitude.
                                        </p>
                                        <div className="col-md-6">
                                            <label htmlFor="nom" className="form-label">
                                                Nom
                                            </label>
                                            <input
                                                type="text"
                                                name="nom"
                                                className="form-control no-focus-outline mb-2"
                                                id="nom" value={clientUpdate.nom} onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="prenom" className="form-label">
                                                Prenom
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control no-focus-outline mb-2"
                                                id="prenom"
                                                name="prenom" value={clientUpdate.prenom} onChange={onChange}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="numeroTel" className="form-label">
                                                Telephone
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control no-focus-outline mb-2"
                                                id="numeroTel"
                                                name="numeroTel" value={clientUpdate.numeroTel} onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="localisation" className="form-label">
                                                Localisation
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control no-focus-outline mb-2"
                                                id="localisation"
                                                name="localisation" value={clientUpdate.localisation} onChange={onChange}
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="sexe">
                                                Sexe
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <select className="form-control mb-2" id='sexe' name='sexe' onChange={onChange}>
                                                <option value="" className='text-info' disabled selected>Selectionner votre sexe</option>
                                                <option value="masculin">Masculin</option>
                                                <option value="feminin">Feminin</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className='fas fa-times f-3 mr-1'></i>Fermer</button>
                                        <div className="d-flex">
                                            {/* <button type="reset" className="btn btn-secondary btn-sm mr-2" ><i className='fas fa-undo mr-1 f-3' aria-hidden="true" ></i>Annuler</button> */}

                                            {
                                                wait ?
                                                    <button type="submit" className="btn btn-warning btn-sm" title='Modifier'> <i className='fas fa-edit mr-1 f-3'></i>Modifier</button>
                                                    :
                                                    <button className="btn btn-warning btn-sm"> <span className="spinner-border text-black spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Modification..</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListClient;