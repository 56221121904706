/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logom from "../../assets/user/user/4.jpg";
import { authentification } from '../../services/authentification';
import toast from 'react-hot-toast';
import swal from 'sweetalert';
const ProfileCompany = () => {
    const navigate = useNavigate();
    const home = () => {
        navigate('/company/dashboad');
    };

    const [userProfile, setUserProfile] = useState([])
    // get user profile
    useEffect(() => {
        authentification.profileCompany()
            .then((res) => {
                console.log("userProfileRes ::: ", res.data)
                setUserProfile(res.data)
            })
            .catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 400) {
                        navigate("/auth/loginCompany");
                        swal({
                            text: "Votre séssion a expiré, veillez vous connecter",
                            icon: "error",
                            button: "Fermer",
                        });
                    }
                    if (err.response.status === 404) {
                        toast("Utilisateur introuvable")
                    }
                    if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application , veillez vous reconnecter.")
                    }
                }
            }


            )
    }, []);
    localStorage.setItem('connectedUserCompany', userProfile.nomBoss);


    const date = userProfile.date
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Profil</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li onClick={home} className="breadcrumb-item link">Acceuil</li>
                                <li className="breadcrumb-item active">Profil</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-6">
                            <div className="card bordure ">
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 390 }}>
                                            <div className="img text-center">
                                                <img src={logom} height={200} width={300} alt="imageProfile" />
                                            </div>
                                            {/* <hr />
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span><i className="fas fa-calendar mr-2" />Date de création :  </span>
                                                <span>{date}</span>
                                            </div> */}
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span><i className="fas fa-user mr-2" />Nom du proprietaire :  </span>
                                                <span className='capitalize'>{userProfile.nomBoss}</span>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="col-lg-6">
                            <div className="card bordure ">
                                <div className="card-header p-1 ">
                                    <div className="card-title">
                                        <h5 className='title-card  m-0 text-center text-md-left'>
                                            <i class="fa fa-info-circle icon ml-1 mr-2"></i>
                                            Informations générales
                                        </h5>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 360 }}>
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span > <i class="fas fa-user mr-2 "></i>Raison social :  </span>
                                                <span className='capitalize'>{userProfile.nom}</span>
                                            </div>
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span> <i className='fas fa-map-marker-alt mr-2'></i>Localisation :  </span>
                                                <span>{userProfile.localisation}</span>
                                            </div>
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span><i class="fas fa-phone  mr-2" />Téléphone : </span>
                                                <span>(+237) {userProfile.numeroTel}</span>
                                            </div>
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span><i class="fas fa-clipboard  mr-2" />Secteur d'activité :  </span>
                                                <span className=''>{userProfile.Activite}</span>
                                            </div>
                                            <hr />
                                            <div className="info d-flex justify-content-between mt-4">
                                                <span><i class="fas fa-user-circle  mr-2" />Nombre de clients  :  </span>
                                                <span>{userProfile.nbrClient}</span>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div >
            </section >
        </div >
    );
};

export default ProfileCompany;