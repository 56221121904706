import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { customStyles } from '../../components/datatable/customStyles';
import logow from "../../assets/company/téléchargement3.png";
import { company_services } from '../../services/company_services';
import toast from 'react-hot-toast';

const ListCompany = () => {
    // Navigations
    const navigate = useNavigate()
    const add_company = () => {
        navigate('/add/company');
    }
    const home = () => {
        navigate('/');
    };
    const list_company = () => {
        navigate('/list/company');
    };

    // declaration 
    const [wait, setWait] = useState(true)
    const [state, setState] = useState(0)
    const [id, setId] = useState('')
    const [company, setCompany] = useState({})
    const [companies, setCompanies] = useState([])
    const [updateId, setUpdateId] = useState('')
    const [companyUpdate, setCompanyUpdate] = useState({})
    const [activitie, setActivitie] = useState([])
    const [commercial, setCommercial] = useState([])
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('');
    const [sortFilter, setSortFilter] = useState('');
    const [sortStatus, setSortStatus] = useState('');
    const [sortCommercial, setSortCommercial] = useState('');
    const [typeIntervention, setTypeIntervention] = useState([])

    // get conneted user role
    const role = localStorage.getItem("role")

    // get all Type Intervention function
    useEffect(() => {
        company_services.getAllTypeIntervention()
            .then((res) => {
                console.log("typeInterventionRes : ", res.data.typeIntervention)
                setTypeIntervention(res.data.typeIntervention)
            })
            .catch((err) => console.log(err))
    }, [])

    // get all commercial function
    useEffect(() => {
        company_services.getAllCommercial()
            .then((res) => {
                // console.log("commercialRes : ", res.data.commercial)
                setCommercial(res.data.commercial)
            })
            .catch((err) => console.log(err))
    }, [])

    // update company 
    useEffect(() => {
        if (updateId !== '') {
            company_services.getCompany(updateId)
                .then((res) => {
                    console.log("userUpdateRES ::", res.data)
                    setCompanyUpdate(res.data)
                })
                .catch((err) => {
                    console.error("err :", err);
                });
        }
    }, [updateId]);

    const onChange = (e) => {
        setCompanyUpdate({
            ...companyUpdate,
            [e.target.name]: e.target.value
        })
    }
    console.log("companyUpdate---------> ", companyUpdate);
    const onSubmit = (e) => {
        e.preventDefault()
        if (
            !companyUpdate.nom ||
            !companyUpdate.numeroTel ||
            !companyUpdate.nomBoss ||
            !companyUpdate.typeInterventionId ||
            !companyUpdate.localisation
        ) {
            toast.error("Veuillez remplir tous les champs obligatoires!");
            return;
        }

        if (companyUpdate.numeroTel && companyUpdate.numeroTel.length < 9) {
            toast.error("Le numero de telephone doit contenir au moins 9 chiffres.");
            return;
        }

        if (companyUpdate.nom && companyUpdate.nom.length > 30) {
            toast.error("Le champ nom ne doit pas etre au dela de 30 caracteres.");
            return;
        }

        setWait(false);

        console.log("updateId::::", updateId);

        company_services.updateCompany(updateId, companyUpdate)
            .then((res) => {
                console.log("getcompanyUpdate : ", res)
                if (res.status === 201) {
                    setState((current) => current + 1)
                    navigate("/list/company")
                    toast.success("Structure modifier avec succes.")
                    // Fermer la modal
                    document.getElementById('modal-edit').classList.remove('show');
                    document.getElementById('modal-edit').setAttribute('aria-hidden', 'true');
                    document.body.classList.remove('modal-open');
                    document.querySelector('.modal-backdrop').remove();
                }
                setWait(true);
            })
            // setUserUpdate(res.data[0])
            .catch((err) => {
                setWait(true);
                console.log(err)
                if (err.response) {

                    if (err.response.status === 423) {
                        toast.error("Cette email a deja ete utiliser , essayer une autre!")
                    }
                    if (err.response.status === 424) {
                        toast.error("Ce numero a deja ete utiliser , essayer un autre!")
                    }
                    if (err.response.status === 404) {
                        toast.error("Utilisateur introuvable.")
                    }

                    if (err.response.status === 406) {
                        toast.error("Vous n'etes pas autoriser a effectuer cette action.")
                    }

                    if (err.response.status === 500) {
                        toast.error("Erreur d'exécution dans l'application.")
                    }

                }
            })
    }
    // detail company info
    // console.log("idRes :", id);
    useEffect(() => {
        if (id !== '') {
            company_services.getCompany(id)
                .then((res) => {
                    console.log("companyDetailRes :", res.data);
                    setCompany(res.data)
                })
                .catch((err) => {
                    console.error("err :", err);
                });
        }
    }, [id, state]);

    // toggle company status
    const changeCompanytatus = (id) => {
        company_services.toggleCompanyStatus(id)
            .then(res => {
                console.log('toggleStatut : ', res);
                if (res.data) {
                    setState((current) => current + 1)
                    toast.success("Status modifier")
                    // Fermer la modal
                    document.getElementById('modal-detail').classList.remove('show');
                    document.getElementById('modal-detail').setAttribute('aria-hidden', 'true');
                    document.body.classList.remove('modal-open');
                    document.querySelector('.modal-backdrop').remove();
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 203) {
                        toast.error("Vous ne pouvez pas désactivé ou activé cet utilisateur")
                    }
                }
                console.error("err : ", err);
            });
    };

    // get all companies function
    const getAllCompanies = () => {
        company_services.getAllCompany()
            .then((res) => {
                console.log("companiesRes : ", res.data.allUser
                )
                setCompanies(res.data.allUser
                )
            })
            .catch((err) => console.log(err))
    }
    console.log("companiesRes : ", companies)

    // Retrieve the users when the component is mounted and each time the sorting/filtering criteria change
    useEffect(() => {
        getAllCompanies();
    }, [sortOrder, sortFilter, sortStatus, sortCommercial, state]);

    // Définir les colonnes du tableau
    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: '60px',
            sortable: true,
        },
        {
            name: 'Nom',
            selector: (row) => row.nom,
            sortable: true,
        },
        // {
        //     name: 'Nom.Proprietaite',
        //     selector: (row) => row.nomBoss,
        //     sortable: true,
        // },
        {
            name: 'Téléphone',
            selector: (row) => row.numeroTel,
            sortable: true,
        },
        {
            name: 'Localisation',
            selector: (row) => row.localisation,
            sortable: true,
        },
        {
            name: 'Code.unique',
            selector: (row) => row.codeUnique,
            sortable: true,
        },
        // {
        //     name: 'Nbr clients',
        //     selector: (row) => row.nbrClient,
        //     sortable: true,
        // },
        {
            name: 'Limite',
            selector: (row) => row.limite,
            sortable: true,
        },
        {
            name: 'Intervention',
            selector: (row) => row.typeInterventionId,
            sortable: true,
            cell: (row) => (
                <span >
                    {row.typeInterventionId === 1 ? 'Nb.visite' : 'Quantité'}
                </span>
            ),
        },
        {
            name: 'Statu',
            selector: (row) => row.statut,
            sortable: true,
            width: '80px',
            cell: (row) => (
                <span className={`badge ${row.statut === true ? 'badge-success' : 'badge-danger'}`}>
                    {row.statut === true ? 'Activé' : 'Désactiver'}
                </span>
            ),
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className='action-container d-flex'>
                    <button className="btn btn-primary btn-xs" title='Voir plus' data-toggle="modal" data-target="#modal-detail" onClick={() => setId(row.id)} ><i className="fas fa-eye " /></button>
                    <button className="btn btn-warning btn-xs mx-1" title='Modifier' data-toggle="modal" data-target="#modal-edit" onClick={() => setUpdateId(row.id)}><i className="fas fa-edit" /></button>
                    {
                        role === "admin" ?
                            <div className={`custom-control custom-switch ${row.statut === true ? 'custom-switch-on-danger' : 'custom-switch-off-success'}`} title={row.statut === false ? "Activé la structure" : "Désactivé la structure"}>
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`customSwitch${row.id}`} // Utiliser un identifiant unique pour chaque bouton
                                    checked={row.statut === true}
                                    onClick={() => changeCompanytatus(row.id)} // Passer l'identifiant de l à la fonction
                                />
                                <label className="custom-control-label link" htmlFor={`customSwitch${row.id}`}>
                                </label>
                            </div>
                            :
                            ""
                    }

                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    // Function to filter and sort companies based on current status
    const getCompaniesSortAdnfilter = () => {
        let companiesFilters = companies

        // Apply status filter
        if (sortStatus) {
            companiesFilters = companiesFilters.filter((companies) => companies.statut === sortStatus);
        }

        // Apply commercial filter
        if (sortCommercial) {
            companiesFilters = companiesFilters.filter((companies) => companies.utilisateurId === sortCommercial);
        }

        // sort companies
        companiesFilters.sort((a, b) => {
            if (sortOrder === 'desc') {
                if (sortFilter === 'nom') {
                    return b.nom.localeCompare(a.nom);
                } else if (sortFilter === 'nomBoss') {
                    return b.nomBoss.localeCompare(a.nomBoss);
                } else if (sortFilter === 'localisation') {
                    return b.localisation.localeCompare(a.localisation);
                }
            } else {
                if (sortFilter === 'nom') {
                    return a.nom.localeCompare(b.nom);
                } else if (sortFilter === 'nomBoss') {
                    return a.nomBoss.localeCompare(b.nomBoss);
                } else if (sortFilter === 'localisation') {
                    return a.localisation.localeCompare(b.localisation);
                }
            }
        });

        return companiesFilters
    }

    // Mettre à jour les données filtrées et triées chaque fois que l'un des états change
    useEffect(() => {
        const sortedFilteredGuests = getCompaniesSortAdnfilter();
        setFilteredData(sortedFilteredGuests);
    }, [companies, sortOrder, sortFilter, sortStatus, sortCommercial]);

    // Filtrer les données en fonction du texte de recherche
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        const filtered = getCompaniesSortAdnfilter().filter(item => {

            return (
                item.nom.toLowerCase().includes(value.toLowerCase()) ||
                item.nomBoss.toLowerCase().includes(value.toLowerCase()) ||
                item.localisation.toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };

    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Gestion des structures</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item link"><a onClick={home}>Acceuil</a></li>
                                    <li className="breadcrumb-item link"><a onClick={list_company}>Structures</a></li>
                                    <li className="breadcrumb-item active">Liste des Structures</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title"><i className="fas fa-list mr-2 text-light"></i>Liste des Structures</h3>
                                        {/* <strong className='total_entree'>   Total : {companies.length}</strong> */}
                                        <button className="btn btn-light text-success shadow btn-xs" onClick={add_company} title="Ajouter une structure" style={{ position: 'relative', float: 'right' }}>
                                            <i className="mr-1 fas fa-plus" />
                                            Ajouter
                                        </button>
                                    </div>

                                    <div className="card-body">
                                        <form className="filtre align-items-center d-flex justify-content-between flex-wrap">
                                            <div>
                                                <label htmlFor="orderOption"> Trier par..</label>
                                                <select className="" value={sortOrder} name={sortOrder} onChange={(e) => setSortOrder(e.target.value)} id='orderOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="asc">Ordre croissant</option>
                                                    <option value="desc">Ordre decroissant</option>
                                                </select>
                                            </div>

                                            <div>
                                                <label htmlFor="filterOption">Filtrer par..</label>
                                                <select className="ml-2" value={sortFilter} name={sortFilter} onChange={(e) => setSortFilter(e.target.value)} id='filterOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="nom">Nom</option>
                                                    <option value="nomBoss">Responsable</option>
                                                    <option value="localisation">Localisation</option>
                                                </select>
                                            </div>

                                            {/* <div>
                                                <label htmlFor="statusOption"> Status..</label>
                                                <select className=" ml-2" value={sortStatus} name={sortStatus} onChange={(e) => setSortStatus(e.target.value)} id='statusOption'>
                                                    <option value="" selected >---</option>
                                                    <option value="0">Actif</option>
                                                    <option value="1">Inactif</option>
                                                </select>
                                            </div> */}

                                            {/* <div>
                                                <label htmlFor="commercialOption">Commercial..</label>
                                                <select className="capitalize  ml-2" name={sortCommercial} onChange={(e) => setCommercial(e.target.value)} id='typeOption'>
                                                    <option selected>---</option>
                                                    {
                                                        commercial.map((item, index) => (
                                                            <option key={index} value={item.code}>
                                                                {item.nom + " " + item.prenom}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div> */}

                                            <div className="">
                                                <input
                                                    className='search'
                                                    type="text"
                                                    placeholder="Rechercher..."
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </form>
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            striped
                                            highlightOnHover
                                            customStyles={customStyles}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* modals */}
            {/* update company modal */}
            <div className="modal fade" id="modal-edit">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Modification de la structures  : <strong className='ml-2 capitalize'> {companyUpdate.nom}</strong> </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form className="row form" onSubmit={onSubmit}>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row ">
                                        <p className=''>
                                            Veuillez vérifier le formulaire et mettre a jour les informations de la structure pour assurer leur exactitude.
                                        </p>
                                        <div className="col-md-6">
                                            <label htmlFor="nom" className="form-label" >
                                                Nom de la structure
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="nom"
                                                className="form-control no-focus-outline mb-2"
                                                id="nom"
                                                autoComplete='off'
                                                value={companyUpdate.nom} onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="nomBoss" className="form-label">
                                                Nom du responsable
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control no-focus-outline mb-2"
                                                id="nomBoss" name="nomBoss" autoComplete='off'
                                                value={companyUpdate.nomBoss} onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="localisation" className="form-label">
                                                Localisation
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control no-focus-outline mb-2" id="localisation"
                                                name="localisation" autoComplete='off'
                                                value={companyUpdate.localisation} onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="numeroTel" className="form-label">
                                                Telephone
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control no-focus-outline mb-2"
                                                id="numeroTel"
                                                name="numeroTel" autoComplete='off'
                                                value={companyUpdate.numeroTel} onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="typeInterventionId">
                                                Type d'intervention
                                                <span className="text-danger taille_etoile">*</span>
                                            </label>
                                            <select className="form-control mb-2 capitalize" name='typeInterventionId' id='typeInterventionId' onChange={onChange}>
                                                <option value="" disabled selected>Sélectionner le type d'intervention </option>
                                                {
                                                    typeIntervention.map((item, index) => (
                                                        <option className='capitalize' key={index} value={item.id}>
                                                            {item.type}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className='fas fa-times f-3 mr-1'></i>Fermer</button>
                                        <div className="d-flex">
                                            {
                                                wait ?
                                                    <button type="submit" className="btn btn-warning btn-sm" title='Modifier'> <i className='fas fa-edit mr-1 f-3'></i>Modifier</button>
                                                    :
                                                    <button className="btn btn-warning btn-sm"> <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>Modification..</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* company detail modal */}
            <div className="modal fade" id="modal-detail">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title modal-title-sm">Détail de la strucure  : <strong className='ml-2 capitalize'>{company.nom}</strong> </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button></div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-md-6 shadow align-items-center justify-content-center">
                                        <div className="logo text-center  px-2 py-2">
                                            <img src={logow} alt="logo Structure" width="300px" height="300px" />                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="fw-bold name mt-2"><h4><strong className='capitalize shadow px-2 py-2'>{company.nom}</strong></h4></div>

                                        <div className="mt-3 capitalize"><i className='fas fa-user mr-2 ml-2 '></i>{company.nomBoss}</div>

                                        <div className="mt-3"> <i className='fas fa-map-marker-alt mr-2 ml-2'></i>{company.localisation}</div>

                                        <div className="mt-3"><i className='fas fa-wrench  mr-2 ml-2'></i>
                                            <span >
                                                {company.typeInterventionId === 1 ? 'Nb.visite' : company.typeInterventionId === 2 ? 'Quantité' : ""}
                                            </span>
                                        </div>

                                        <div className="mt-3"><i className='fas fa-phone mr-2 ml-2'></i> (+237) {company.numeroTel} </div>

                                        <div className="mt-3">
                                            <i className='fas fa-users mr-2 ml-2'></i>
                                            {company.nbrClient} clients
                                        </div>

                                        <div className="mt-3 ml-2"><span className={company.statut === true ? "badge badge-success" : "badge badge-danger"}>{company.statut === true ? "Activé" : "Désactivé"}</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer  justify-content-between">
                            <button className="btn btn-secondary btn-sm" data-dismiss="modal" title='Fermer'><i className="fas fa-times mr-1"></i>Fermer</button>

                            <div className="button">
                                <button onClick={() => changeCompanytatus(company.id)} className={company.statut === true ? "btn btn-danger btn-sm" : "btn btn-success btn-sm"} title={company.statut === true ? "Désactiver la structure" : "Activer la structure"}>
                                    {company.statut === true ? "Désactivé" : "Activé"}
                                </button>
                                <button type="button" className="btn btn-warning btn-sm ml-2" title='Modifier' data-dismiss="modal" data-toggle="modal" data-target="#modal-edit" onClick={() => setUpdateId(company.id)}>
                                    <i className="fas fa-edit mr-1" title="Modifier les info l'utilisateur"></i>
                                    Modifier
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListCompany;